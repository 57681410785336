<template>
  <div class="user-managment h-full w-full p-2 bg-gray-100">
    <PageHeader>
      <template v-slot:brand>
        <div class="page-brand flex items-center mr-3">
          <div class="flex items-center mr-3">
            <img src="@/assets/images/menu/user-active.svg" class="icon mr-2">
            <span class=" text-black">System Notification</span>
          </div>
          <div class="tabs ml-12">
            <widgetTabs :tabs="[{ title: 'CRM', value: 'crm' }, { title: 'STUDENT', value: 'student' }]" v-model="tab"
              @change="onTabChange"></widgetTabs>
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <div class="flex">
          <div class="h-spacer"></div>
          <widgetButton :loading="false" @click="addNewReasenote()" class="btn-secondary">
            Add Release +
          </widgetButton>
        </div>
      </template>
    </PageHeader>
    <div class="page-body">

      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs  uppercase 0 dark:text-black">
          <tr>
            <th scope="col" class="px-6 py-3">
              Release Title
            </th>
            <th scope="col" class="px-6 py-3">
              Release Version
            </th>
            <th scope="col" class="px-6 py-3">
              Release date
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white dark:border-gray-700 dark:text-black hover:bg-slate-300 hover:cursor-pointer"
            v-for="(item, index) in release" :key="index">
            <th scope="row" @click="showReleaseNotesDetails(item)">
              {{ item.release_title }}
            </th>
            <td class="px-6 py-4" @click="showReleaseNotesDetails(item)">
              {{ item.release_version }}
            </td>
            <td class="px-6 py-4" @click="showReleaseNotesDetails(item)">
              {{ item.created_at | formatDate }}
            </td>
            <td class="px-6 py-4" @click="showReleaseNotesDetails(item)">
              {{ item.status == 'publish' ? 'Published' : 'Draft' }}
            </td>
            <td class="px-6 py-4">
              <widgetDropdown v-model="item.show_dropdown" :right="true" :hover="false" :interactive="true"
                class="selected-dropdown">
                <button class="btn-dropdown">
                  <img class="icon" style="height: 20px" src="@/assets/icon/menu-dot.svg" />
                </button>
                <div slot="dropdown" class="dropdown-widget right">
                  <a class="dropdown-item" href="#" @click="openWikiLink(item)">Open Wiki Link</a>
                  <a class="dropdown-item" href="#" @click="showUpdateModal(item)">Edit</a>
                  <app-dialog title="Delete Release Note"
                    ask="Are you sure you want to permanently delete this release note? Everthing related to this release note will be deleted."
                    yesText="Delete" noText="Cancel" @onComfirm="deleteRelease(item)">
                    <a class="dropdown-item" href="#">Delete</a>
                  </app-dialog>
                </div>
              </widgetDropdown>
            </td>
          </tr>

        </tbody>
      </table>

      <div class="content-loading-container" v-if="isLoadingReleaseNotes">
        <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="isLoadingReleaseNotes"
          :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
      </div>
    </div>

    <AddUpdateReleaseNotes v-if="show_update_release_notes_modal" @close="show_update_release_notes_modal = false"
      :value="selected_release_notes" :type="selected_tab" @sucess="onReleaseNoteSaved()">
    </AddUpdateReleaseNotes>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader.vue";
import AddUpdateReleaseNotes from "./modals/AddUpdateReleaseNotes.vue"
export default {
  name: 'ReleaseNotes',
  components: {
    PageHeader,
    AddUpdateReleaseNotes
  },
  data() {
    return {
      release: [],
      isLoadingReleaseNotes: false,
      show_update_release_notes_modal: false,
      selected_release_notes: null,
      show_release_notes_details: false,
      tab: 1,
      selected_tab: "crm"
    }
  },
  mounted() {
    let vm = this;
    vm.getRelease("init");
  },
  methods: {
    openWikiLink(item) {
      window.open(item.wiki_link, '_blank');
    },
    onTabChange(tab) {
      let vm = this;
      vm.selected_tab = tab.value;
      vm.getRelease();
    },
    addNewReasenote() {
      let vm = this;
      vm.show_update_release_notes_modal = true;
    },
    onReleaseNoteSaved() {
      let vm = this;
      vm.show_update_release_notes_modal = false;
      vm.getRelease();
    },
    showUpdateModal(item) {
      let vm = this;
      vm.selected_release_notes = item;
      vm.show_update_release_notes_modal = true;
    },
    deleteRelease(item) {
      let vm = this;
      vm.$store
        .dispatch("RELEASE_DELETE", { id: item.id })
        .then((response) => {
          vm.getRelease();
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Release deleted successfully",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Failed to delete release",
            type: "error",
            duration: 3000,
          });
        });
    },
    getRelease() {
      let vm = this;
      vm.isLoadingReleaseNotes = true;
      vm.$store
        .dispatch("RELEASE_GET_ALL", {
          type: vm.selected_tab
        })
        .then((response) => {
          vm.isLoadingReleaseNotes = false;
          vm.release = response.data;
        })
        .catch((error) => {
          vm.isLoadingReleaseNotes = false;
          console.log(error);
        });
    },
    showReleaseNotesDetails(item) {
      let vm = this;
      vm.selected_release_notes = item;
      vm.show_release_notes_details = true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-body {
  background: #fff;
  height: calc(100% - 70px);
  border-radius: 4px;
  margin-top: 10px;
  overflow-y: auto;
  padding: 0px 20px;
}
</style>
