<template>
  <div class="user-managment h-full w-full p-2 bg-gray-100">
    <PageHeader>
      <template v-slot:brand>
        <div class="page-brand flex items-center mr-3">
          <img src="@/assets/images/menu/user-active.svg" class="icon mr-2">
          <span class=" text-black">User management</span>
        </div>
      </template>

      <template v-slot:path>
        <div class="nav-path-widget flex items-center">
          <div class="nav-path mr-4 text-green-600">All Schools</div>
        </div>
      </template>

      <template v-slot:actions>
        <form>
          <div class="relative">
            <input type="search" id="default-search" @keyup="onSearchSchool"
              class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500   dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search here ..." required>
            <div class="absolute inset-y-0 start-0  flex items-center ps-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
          </div>
        </form>
      </template>
    </PageHeader>
    <div class="page-body" @scroll="onPageScoll">

      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs  uppercase 0 dark:text-black">
          <tr>
            <th scope="col" class="px-6 py-3">
              Schools
            </th>
            <th scope="col" class="px-6 py-3">
              Location
            </th>
            <th scope="col" class="px-6 py-3">
              Contacts
            </th>
            <th scope="col" class="px-6 py-3">
              Created Date
            </th>
            <th scope="col" class="px-6 py-3">
              Created By
            </th>
            <th scope="col" class="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white   dark:border-gray-700 dark:text-black hover:bg-slate-300 hover:cursor-pointer"
            v-for="(item, index) in schools.data" :key="index">
            <th scope="row">
              <widgetProfile :title="item.name" subtitle="" :file_name="item.logo" :path="$fullUrl('images/profiles/')"
                @click="showSchoolDetails(item)"></widgetProfile>
            </th>
            <td class="px-6 py-4" @click="showSchoolDetails(item)">
              {{ item.address }}
            </td>
            <td class="px-6 py-4" @click="showSchoolDetails(item)">
              {{ item.email }}
            </td>
            <td class="px-6 py-4" @click="showSchoolDetails(item)">
              {{ item.created_at | formatDate }}
            </td>
            <td class="px-6 py-4" @click="showSchoolDetails(item)">
              -
            </td>
            <td class="px-6 py-4">
              <widgetDropdown v-model="item.show_dropdown" :right="true" :hover="false" :interactive="true"
                class="selected-dropdown">
                <button class="btn-dropdown">
                  <img class="icon" style="height: 20px" src="@/assets/icon/menu-dot.svg" />
                </button>
                <div slot="dropdown" class="dropdown-widget right">
                  <a class="dropdown-item" href="#" @click="showUpdateModal(item)">Edit</a>
                  <app-dialog title="Delete School"
                    ask="Are you sure you want to permanently delete this school? Everthing related to this school will be deleted."
                    yesText="Delete" noText="Cancel" @onComfirm="deleteSchool(item)">
                    <a class="dropdown-item" href="#">Delete</a>
                  </app-dialog>
                </div>
              </widgetDropdown>
            </td>
          </tr>

        </tbody>
      </table>

      <div class="content-loading-container" v-if="isLoadingSchools">
        <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="isLoadingSchools"
          :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
      </div>

    </div>

    <UpdateSchool v-if="show_update_school_modal" :value="selected_shool" @sucess="onUpdatedSchoolSuccess"
      @close="show_update_school_modal = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader.vue";
import ProfileWidget from "@/components/ProfileWidget.vue";
import UpdateSchool from "./modals/updateSchool.vue";

export default {
  name: 'Management',
  components: {
    PageHeader,
    ProfileWidget,
    UpdateSchool
  },
  data() {
    return {
      schools: [],
      filter: {
        search: '',
        url_type: 'init',
        next_page_url: ""
      },
      isLoadingSchools: false,
      show_update_school_modal: false,
      selected_shool: null
    }
  },
  mounted() {
    let vm = this;
    vm.getSchools("init");
  },
  methods: {
    onPageScoll({ target }) {
      let vm = this;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        if (
          !vm.isLoadingSchools &&
          vm.schools.current_page < vm.schools.last_page
        ) {
          vm.getSchools("next");
        }
      }
    },
    onUpdatedSchoolSuccess() {
      let vm = this;
      vm.show_update_school_modal = false;
      vm.getSchools();
    },
    showUpdateModal(item) {
      let vm = this;
      vm.selected_shool = item;
      vm.show_update_school_modal = true;
    },
    onSearchSchool(e) {
      let vm = this;
      const target = e.target;
      const query = target.value;
      vm.filter.search = query;
      vm.getSchools();
    },
    deleteSchool(item) {
      let vm = this;
      vm.$store.commit("SET_COMPANY_ID", item.id);
      vm.$store
        .dispatch("DELETE_SCHOOL", { id: item.id })
        .then((response) => {
          vm.getSchools();
          vm.$notify({
            group: "status",
            title: "Message",
            text: "School deleted successfully",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Failed to delete school",
            type: "error",
            duration: 3000,
          });
        });
    },
    getSchools(type = "init") {
      let vm = this;
      vm.isLoadingSchools = true;
      if (type == "init") {
        vm.schools = null;
      }
      vm.filter.url_type = type;
      vm.filter.next_page_url = vm.schools
        ? vm.schools.next_page_url
        : "";
      vm.$store
        .dispatch("GET_SCHOOLS", vm.filter)
        .then((response) => {
          vm.isLoadingSchools = false;
          if (type == "init") {
            vm.schools = response.data;
          } else {
            vm.schools.data = vm.schools.data.concat(response.data.data);
            vm.schools.current_page = response.data.current_page;
            vm.schools.next_page_url = response.data.next_page_url;
          }
        })
        .catch((error) => {
          vm.isLoadingSchools = false;
          console.log(error);
        });
    },
    showSchoolDetails(item) {
      this.$router.push({ path: '/user/school/' + item.id });
    }
  }
}
</script>

<style lang="scss" scoped>
.page-body {
  background: #fff;
  height: calc(100% - 70px);
  border-radius: 4px;
  margin-top: 10px;
  overflow-y: auto;
}
</style>
