<template>
  <div class="parent-container">
    <div class="teams-wrapper">
      <div class="tab-options"></div>
      <div class="teams-widget">
        <multipane class="custom-resizer" layout="vertical">
          <div class="teams-list">
            <loading :height="25" color="#1ba5a4" :opacity="1" :width="25" :active.sync="teams_loading"
              :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
            <div class="teams-header">
              <div class="team-title-options">
                <h1 class="team-title">
                  All schools({{ department_data.length }})
                </h1>

                <div class="options"></div>
              </div>
            </div>

            <!-- Super manager and Departement manager view  -->
            <div class="teams-body">
              <div class="departiment-item" v-for="(item, i) in department_data" :key="i"
                :class="{ expanded: item.expanded }" @dragover="depRowDradOver(item, i)">
                <div class="department-header" :class="{ active: item.expanded }" @click.self="expandSchool(item, i)">
                  <div class="start" @click="expandSchool(item, i)">
                    <label class="dep-label">{{ item.name | validate }} </label>
                  </div>
                  <div class="options">
                    <widgetButton v-if="item.expanded" :loading="false" class="btn-secondary" @click="addNewTeam(item)">
                      Add class +
                    </widgetButton>
                    <div v-else @click="expandSchool(item, i)" class="class-count-label">
                      {{ item.teams.length | validate }} Classes
                      <img v-if="item.expanded" class="arrow-icon" src="@/assets/admin-icon/allow-green-up.svg" />
                      <img class="arrow-icon" v-else src="@/assets/admin-icon/allow-down.svg" />
                    </div>
                    <dropdownMenu>
                      <ul>
                        <li>
                          <a @click.prevent="addNewTeam(item)" href="#">Add Class</a>
                        </li>
                        <li>
                          <a @click.prevent="updateSchool(item)" href="#">Edit</a>
                        </li>
                        <li>
                          <app-dialog title="Delete school"
                            ask="Are you sure you want to permanently delete this school?" yesText="Delete"
                            noText="Cancel" @onComfirm="deleteSchool(item)">
                            <a @click.prevent="" href="#">Delete</a>
                          </app-dialog>
                        </li>
                      </ul>
                    </dropdownMenu>
                  </div>
                </div>
                <div class="department-body" v-show="item.expanded && item.teams.length > 0">
                  <table class="table-data department-table">
                    <thead class="table-header">
                      <tr>
                        <th>Class name</th>
                        <th>Instructor</th>
                        <th>Students</th>
                        <th width="90">Actions</th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <template v-for="(team, index) in item.teams">
                        <tr @dragover="teamRowDradOver($event, team, index)"
                          @dragenter="teamRowDradEnter($event, team, index)"
                          @dragleave="teamRowDradLeave($event, team, index)"
                          @drop="teamRowDradDrop($event, team, index)" :key="index" class="row-data"
                          :class="{ hovered: team.hovered }">
                          <td @click="selectTeam(item, team)">
                            <widgetProfile :title="team.name" subtitle="" file_name="" path=""
                              @click="selectTeam(item, team)"></widgetProfile>
                          </td>
                          <td @click="selectTeam(item, team)">
                            <div class="instructor-widget" v-if="team.managers.length > 0">
                              {{
                                (team.managers[0].first_name +
                                  " " +
                                  team.managers[0].last_name)
                                | validate
                              }}
                            </div>
                          </td>
                          <td @click="selectTeam(item, team)">
                            <span class="team-member" v-if="team.consultants && team.consultants.length > 0
                            ">
                              {{ team.consultants.length | validate }}
                            </span>
                            <span v-else class="team-member">No students</span>
                          </td>
                          <td>
                            <div class="center">
                              <dropdownMenu>
                                <ul>
                                  <li>
                                    <app-dialog title="Are you sure?" ask="This action will move class to alumni?"
                                      yesText="Continue" noText="Cancel" @onComfirm="closeTeam(team, item, index)">
                                      <a @click.prevent="" href="#">Close Class</a>
                                    </app-dialog>
                                  </li>
                                  <li>
                                    <a @click.prevent="updateTeam(team)" href="#">Edit</a>
                                  </li>

                                  <li>
                                    <app-dialog title="Delete Class" ask="Are you sure you want to delete this class?"
                                      yesText="Delete" noText="Cancel" @onComfirm="deleteTeam(team, item, index)">
                                      <a @click.prevent="" href="#">Delete</a>
                                    </app-dialog>
                                  </li>
                                </ul>
                              </dropdownMenu>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div v-if="item.expanded && item.teams.length == 0" class="department-body">
                  <label>No class in this school, you can add one on menu</label>
                </div>
              </div>
            </div>

          </div>
          <multipane-resizer></multipane-resizer>
          <div v-if="loading_team_member || team_data" class="teams-details" :style="{ flexGrow: 1 }">
            <loading :height="25" color="#1ba5a4" :opacity="1" :width="25" :active.sync="loading_team_member"
              :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
            <div v-if="team_data" class="team-container-header">
              <div class="teams-header">
                <div class="team-info">
                  <widgetProfile :title="team_data.name" :subtitle="selected_department.name" file_name="" path="">
                  </widgetProfile>
                </div>
              </div>
              <div class="search-add-btn-widget" v-if="team_data.consultants.length > 0">
                <widgetSearch icon="right" v-model="search_member_input" placeholder="Type in to Search…">
                </widgetSearch>
              </div>
            </div>
            <div v-if="team_data" class="team-container-body">
              <table class="table-data" border="0" v-if="team_data.consultants.length > 0">
                <thead class="table-header">
                  <tr>
                    <th>Student Name</th>
                    <th>Contact</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr v-for="(item, index) in team_data.consultants" :key="index" draggable="true"
                    @dragstart="memberRowDradStart($event, item, index)" @dragend="memberRowDragEnd"
                    @drop="memberRowDradDrop($event, team_data, index)">
                    <td>
                      <div class="tb-profile-widget">
                        <widgetProfile :title="item.first_name + ' ' + item.last_name"
                          :subtitle="item.profile ? item.profile.address : ''" :file_name="item.profile_picture"
                          :path="$fullUrl('images/profiles/')"></widgetProfile>
                      </div>
                    </td>

                    <td>
                      <div class="tb-profile-email">
                        {{ item.email | validate }}
                      </div>
                      <p v-if="item.profile">
                        {{ item.profile.phone | validate }}
                      </p>
                    </td>

                    <td v-if="false">
                      <dropdownMenu>
                        <ul>
                          <li>
                            <app-dialog title="Remove Member" ask="Are you sure you want to remove this member?"
                              yesText="Delete" noText="Cancel" @onComfirm="removeMember(team_data.id, item.id)">
                              <a href="#">Remove</a>
                            </app-dialog>
                          </li>
                        </ul>
                      </dropdownMenu>
                    </td>
                  </tr>
                </tbody>
              </table>
              <widgetNotFoundWidget v-else title="No Student" content="The selected class has  no student yet"
                align="center">
                <img class="widget-img" src="@/assets/icons/member-notfound.svg" />
              </widgetNotFoundWidget>
            </div>
          </div>

          <div v-if="!loading_team_member && !team_data" :style="{ flexGrow: 1 }" class="teams-details">
            <widgetNotFoundWidget title="Select a Class" content="Select class from the list to view its information"
              align="center">
              <img class="widget-img" src="@/assets/icons/select-item.svg" />
            </widgetNotFoundWidget>
          </div>
        </multipane>
      </div>

      <!-- Team Add - Update Modal -->
      <div class="overlay" v-if="show_form">
        <div class="form-widget-modal">
          <div class="moder-header">
            <h4 v-if="!form.id">Create a new class</h4>
            <h4 v-else>Edit class</h4>
          </div>
          <div class="modal-body">
            <div class="input-group">
              <div class="input-header">
                <label>Class name*</label>
              </div>

              <input type="text" v-model="form.team_name" placeholder="Add class name" class="input-form" />
            </div>

            <div class="input-group">
              <label>Add Instructors*</label>
              <searchSelectInput url="/company/search/manager" :miltiple="true" type="site" v-model="manager"
                placeholder="Search for instructor">
                <template v-slot:result="{ result }">
                  <span class="search-contact-item"><img width="20" v-if="result.profile_picture" :src="$fullUrl('images/profiles/' + result.profile_picture)
                    " />
                    <img v-else width="20" src="@/assets/avatar.png" />
                    {{ result.first_name | validate }}
                    {{ result.last_name | validate }}</span>
                </template>
                <template v-slot:selected="{ selected }">
                  <span class="selected-search-contact-item">
                    <img width="20" v-if="selected.profile_picture" :src="$fullUrl('images/profiles/' + selected.profile_picture)
                      " />
                    <img v-else width="20" src="@/assets/avatar.png" />
                    {{ selected.first_name | validate }}</span>
                </template>
              </searchSelectInput>
            </div>

            <div class="input-group">
              <label>Add Students</label>
              <searchSelectInput url="/search/people" :miltiple="true" action="add-class" type="site" v-model="members"
                placeholder="Search for students...">
                <template v-slot:result="{ result }">
                  <span class="search-contact-item"><img width="20" v-if="result.profile_picture" :src="$fullUrl('images/profiles/' + result.profile_picture)
                    " />
                    <img v-else width="20" src="@/assets/avatar.png" />
                    {{ result.first_name | validate }}
                    {{ result.last_name | validate }}</span>
                </template>
                <template v-slot:selected="{ selected }">
                  <span class="selected-search-contact-item">
                    <img width="20" v-if="selected.profile_picture" :src="$fullUrl('images/profiles/' + selected.profile_picture)
                      " />
                    <img v-else width="20" src="@/assets/avatar.png" />
                    {{ selected.first_name | validate }}</span>
                </template>
              </searchSelectInput>
            </div>
            <div class="input-group">
              <label>LIA Period</label>
              <div class="terms-widget">
                <div class="term-item" :class="{ active: form.team_term == 'first' }" @click="selectClassTerm('first')">
                  First Term
                </div>
                <div class="term-item" :class="{ active: form.team_term == 'second' }"
                  @click="selectClassTerm('second')">
                  Second Term
                </div>
              </div>
              <div class="dates-widget" v-if="form.team_term == 'first'">
                <datepicker :monday-first="true" placeholder="From" input-class="date-field_wrapper"
                  wrapper-class="__date-field" v-model="form.term1_period_from"></datepicker>

                <datepicker :monday-first="true" :disabled-dates="{ to: new Date(form.term1_period_from) }"
                  placeholder="To" input-class="date-field_wrapper" wrapper-class="__date-field"
                  v-model="form.term1_period_to"></datepicker>
              </div>
              <div class="dates-widget" v-if="form.team_term == 'second'">
                <datepicker :monday-first="true" :disabled-dates="{ to: new Date(form.term1_period_to) }"
                  placeholder="From" input-class="date-field_wrapper" wrapper-class="__date-field"
                  v-model="form.term2_period_from"></datepicker>

                <datepicker :monday-first="true" :disabled-dates="{ to: new Date(form.term2_period_from) }"
                  placeholder="To" input-class="date-field_wrapper" wrapper-class="__date-field"
                  v-model="form.term2_period_to"></datepicker>
              </div>
            </div>
            <div class="input-group">
              <label>Description</label>
              <textarea type="text" v-model="form.description" class="input-form"
                placeholder="Class description"></textarea>
            </div>
            <div class="twin-input">
              <div class="input-group">
                <label>Location</label>
                <map-helper v-model="form.location"></map-helper>
              </div>
              <div class="input-group">
                <label>Tags</label>
                <TagsInput v-model="tags" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <c-button :loading="team_loading" @click="submitTeam()" class="btn-save">Save</c-button>
            <button class="btn-cancel" @click="show_form = false">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- End -->

      <!-- School Add - Update Modal -->
      <div class="overlay" v-if="department_show_form">
        <div class="form-widget-modal">
          <div class="moder-header">
            <h4 v-if="!department_form.id">Create a new school</h4>
            <h4 v-else>Edit school</h4>
          </div>
          <div class="modal-body">
            <div class="input-group">
              <div class="input-header">
                <label>School name*</label>
              </div>
              <input type="text" v-model="department_form.department_name" placeholder="Add school name"
                class="input-form" />
            </div>

            <div class="input-group">
              <label>Select Instructor*</label>
              <searchSelectInput url="/company/search/manager" :miltiple="true" type="site" v-model="manager"
                placeholder="Search for instructor">
                <template v-slot:result="{ result }">
                  <span class="search-contact-item"><img width="20" v-if="result.profile_picture" :src="$fullUrl('images/profiles/' + result.profile_picture)
                    " />
                    <img v-else width="20" src="@/assets/avatar.png" />
                    {{ result.first_name | validate }}
                    {{ result.last_name | validate }}</span>
                </template>
                <template v-slot:selected="{ selected }">
                  <span class="selected-search-contact-item">
                    <img width="20" v-if="selected.profile_picture" :src="$fullUrl('images/profiles/' + selected.profile_picture)
                      " />
                    <img v-else width="20" src="@/assets/avatar.png" />
                    {{ selected.first_name | validate }}</span>
                </template>
              </searchSelectInput>
            </div>

            <div class="input-group">
              <label>Description</label>
              <textarea type="text" v-model="department_form.description" class="input-form"
                placeholder="Add description"></textarea>
            </div>

            <div class="input-group">
              <label>Location</label>
              <map-helper v-model="department_form.location"></map-helper>
            </div>
          </div>
          <div class="modal-footer">
            <c-button :loading="team_loading" @click="submitSchool()" class="btn-save">Save</c-button>
            <button class="btn-cancel" @click="department_show_form = false">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- End -->
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
export default {
  name: "classes",
  components: {},
  props: {},
  data: () => {
    return {
      show_form: false,
      form: {
        id: null,
        team_name: "",
        department_id: null,
        managers_added: [],
        managers_removed: [],
        members_added: [],
        members_removed: [],
        team_term: "first",
        term1_period_from: "",
        term1_period_to: "",
        term2_period_from: "",
        term2_period_to: "",
        description: "",
        location: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
      },
      tags: {
        added: [],
        removed: [],
      },
      department_form: {
        id: null,
        department_name: "",
        managers_added: [],
        managers_removed: [],
        description: "",
        location: "",
      },
      team_loading: false,
      teams_loading: false,
      loading_team_member: false,
      active_tab: "dep",
      manager: {
        added: [],
        removed: [],
      },
      members: {
        added: [],
        removed: [],
      },
      department_data: [],
      department_show_form: false,
      teams_data: [],
      team_data: null,
      search_input: "",
      selected_department: null,
      search_member_input: "",
      dragged_member: null,
      dragged_member_index: null,
      active_department: null,
    };
  },
  mounted() {
    let vm = this;
    vm.getTeamDepData();
  },
  computed: {
    isPermited() {
      let vm = this;
      return vm.$localStorage.get("main_role") != "team-manager";
    },
  },
  watch: {
    search_input(val) {
      let vm = this;
      vm.searchAll(val);
    },
    search_member_input(val) {
      let vm = this;
      vm.searchMember(val);
    },
  },
  methods: {
    selectClassTerm(term) {
      let vm = this;
      vm.form.team_term = term;
    },
    deleteSchool(item) {
      let vm = this;
      vm.$store
        .dispatch("ORGANIZATION_DELETE_DEPARTMENT", { id: item.id })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Success",
            text: "School have been deleted",
            type: "success",
            duration: 3000,
          });
          vm.getTeamDepData();
        })
        .catch((error) => { });
    },
    updateSchool(item) {
      let vm = this;
      vm.department_form.id = item.id;
      vm.department_form.department_name = item.name;
      vm.department_form.managers_added = [];
      vm.department_form.managers_removed = [];
      vm.department_form.description = item.description;
      vm.department_form.location = item.location;
      vm.manager = {
        added: [],
        removed: [],
      };
      item.managers.forEach((item) => {
        eventBus.$set(item, "on_update", true);
        vm.manager.added.push(item);
      });
      vm.department_show_form = true;
    },
    submitSchool() {
      let vm = this;
      // For manager
      vm.department_form.managers_added = vm.manager.added.map((item) => {
        return item.id;
      });
      const rules = {
        department_name: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "School can not be empty";
          },
        },
        location: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            } else {
              return "Please select school location";
            }
          },
        },
      };
      if (vm.$isFieldsValidated(vm.department_form, rules)) {
        vm.team_loading = true;
        if (!vm.department_form.id) {
          vm.$store
            .dispatch("ORGANIZATION_ADD_NEW_DEPARTMENT", vm.department_form)
            .then((response) => {
              vm.team_loading = false;
              vm.department_show_form = false;
              vm.$notify({
                group: "status",
                title: "Success",
                text: "School have been added",
                type: "success",
                duration: 3000,
              });
              vm.getTeamDepData();
            })
            .catch((error) => {
              console.error({ error });
              vm.team_loading = false;
            });
        } else {
          // For manager
          vm.department_form.managers_added = vm.manager.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item.id;
            });
          vm.department_form.managers_removed = vm.manager.removed.map(
            (item) => {
              return item;
            }
          );
          vm.$store
            .dispatch("ORGANIZATION_UPDATE_DEPARTMENT", vm.department_form)
            .then((response) => {
              vm.team_loading = false;
              vm.department_show_form = false;
              vm.$notify({
                group: "status",
                title: "Success",
                text: "School have been updated",
                type: "success",
                duration: 3000,
              });
              vm.getTeamDepData();
            })
            .catch((error) => {
              console.error({ error });
              vm.team_loading = false;
            });
        }
      }
    },
    addNewSchool() {
      let vm = this;
      vm.manager = {
        added: [],
        removed: [],
      };
      vm.department_form = {
        id: null,
        department_name: "",
        managers_added: [],
        managers_removed: [],
        description: "",
        location: "",
      };
      vm.department_show_form = true;
    },
    getTeamDepData() {
      let vm = this;
      vm.teams_loading = true;
      vm.$store
        .dispatch("GET_ALL_USER_INFO", {
          classStatus: 'active',
          prefix: vm.$store.getters.get_crm_prefix.replace("/", ""),
          crm_company_id: vm.$route.params.id,
        })
        .then((response) => {
          vm.department_data = response.data.map((item, index) => {
            if (vm.active_department == index) {
              eventBus.$set(item, "expanded", true);
            } else {
              eventBus.$set(item, "expanded", false);
            }
            item.teams = item.teams.map((team) => {
              eventBus.$set(team, "hovered", false);
              return team;
            });
            return item;
          });
          vm.teams_loading = false;
        })
        .catch((error) => {
          vm.teams_loading = false;
          console.error({ error });
        });
    },
    switchType(type) {
      let vm = this;
      vm.active_tab = type;
    },
    updateTeam(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.team_name = item.name;
      vm.form.managers_added = [];
      vm.form.managers_removed = [];
      vm.form.department_id = item.department_id;
      vm.form.members_added = [];
      vm.form.members_removed = [];
      // work on this
      vm.form.team_term = "first";
      vm.form.term1_period_from = item.first_term_start_date;
      vm.form.term1_period_to = item.first_term_end_date;
      vm.form.term2_period_from = item.second_term_start_date;
      vm.form.term2_period_to = item.second_term_end_date;

      // ===
      vm.form.description = item.description;
      vm.form.location = item.location;
      vm.manager = {
        added: [],
        removed: [],
      };
      vm.members = {
        added: [],
        removed: [],
      };
      item.managers.forEach((item) => {
        eventBus.$set(item, "on_update", true);
        vm.manager.added.push(item);
      });
      item.consultants.forEach((item) => {
        eventBus.$set(item, "on_update", true);
        vm.members.added.push(item);
      });
      vm.tags = {
        added: [],
        removed: [],
      };
      item.tags.forEach((item) => {
        eventBus.$set(item, "on_update", true);
        vm.tags.added.push(item);
      });
      vm.show_form = true;
    },
    deleteTeam(item, department, index) {
      let vm = this;
      vm.$store
        .dispatch("ORGANIZATION_DELETE_TEAM", { id: item.id })
        .then((response) => {
          department.teams.splice(index, 1);
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Class have been deleted",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => { });
    },
    closeTeam(item, department, index) {
      let vm = this;
      vm.$store
        .dispatch("ORGANIZATION_CLOSE_TEAM", { id: item.id })
        .then((response) => {
          department.teams.splice(index, 1);
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Class have been closed",
            type: "success",
            duration: 3000,
          });
        }).catch((error) => { });
    },
    submitTeam() {
      let vm = this;
      // Tags
      vm.form.tags = vm.tags.added.map((item) => {
        return item;
      });
      //For consultant
      vm.form.members_added = vm.members.added.map((item) => {
        return item.id;
      });
      // For manager
      vm.form.managers_added = vm.manager.added.map((item) => {
        return item.id;
      });

      const rules = {
        team_name: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Class name can not be empty";
          },
        },
        department_id: {
          run: (value) => {
            if (vm.form.is_in_department && vm.form.is_existing_department) {
              if (value) {
                return false;
              } else {
                return "Something wrong, Reflesh page";
              }
            }
            return false;
          },
        },
        term1_period_from: {
          run: (value) => {
            if (value) {
              return false;
            } else {
              return "Please select first term start period";
            }
          },
        },
        term1_period_to: {
          run: (value) => {
            if (value) {
              return false;
            } else {
              return "Please select first term end period";
            }
          },
        },
        term2_period_from: {
          run: (value) => {
            if (value) {
              return false;
            } else {
              return "Please select second term start period";
            }
          },
        },
        term2_period_to: {
          run: (value) => {
            if (value) {
              return false;
            } else {
              return "Please select second term end period";
            }
          },
        },
        location: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            } else {
              return "Please select team location";
            }
          },
        },
      };

      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.form.term1_period_from = vm
          .moment(vm.form.term1_period_from)
          .format("YYYY-MM-DD");
        vm.form.term1_period_to = vm
          .moment(vm.form.term1_period_to)
          .format("YYYY-MM-DD");
        vm.form.term2_period_from = vm
          .moment(vm.form.term2_period_from)
          .format("YYYY-MM-DD");
        vm.form.term2_period_to = vm
          .moment(vm.form.term2_period_to)
          .format("YYYY-MM-DD");

        vm.team_loading = true;

        if (!vm.form.id) {
          vm.$store
            .dispatch("ORGANIZATION_ADD_NEW_TEAM", vm.form)
            .then((response) => {
              vm.team_loading = false;
              vm.show_form = false;
              vm.$notify({
                group: "status",
                title: "Success",
                text: "Class have been added",
                type: "success",
                duration: 3000,
              });
              vm.getTeamDepData();
            })
            .catch((error) => {
              console.error({ error });
              vm.team_loading = false;
            });
        } else {
          // Tags
          // For tags
          vm.form.tags_added = vm.tags.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item;
            });
          vm.form.tags_removed = vm.tags.removed.map((item) => {
            return item;
          });
          // For manager
          vm.form.managers_added = vm.manager.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item.id;
            });
          vm.form.managers_removed = vm.manager.removed.map((item) => {
            return item.id;
          });

          // For members
          vm.form.members_added = vm.members.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item.id;
            });
          vm.form.members_removed = vm.members.removed.map((item) => {
            return item.id;
          });
          vm.$store
            .dispatch("ORGANIZATION_UPDATE_TEAM", vm.form)
            .then((response) => {
              vm.team_loading = false;
              vm.show_form = false;
              vm.$notify({
                group: "status",
                title: "Success",
                text: "Class have been updated",
                type: "success",
                duration: 3000,
              });
              vm.getTeamDepData();
            })
            .catch((error) => {
              console.error({ error });
              vm.team_loading = false;
            });
        }
      }
    },
    addNewTeam(item) {
      let vm = this;
      vm.show_form = true;
      vm.manager = {
        added: [],
        removed: [],
      };
      vm.members = {
        added: [],
        removed: [],
      };
      vm.form = {
        id: null,
        team_name: "",
        crm_department_id: item.id,
        managers_added: [],
        managers_removed: [],
        members_added: [],
        members_removed: [],
        team_term: "first",
        team_period_from: "",
        team_period_to: "",
        description: "",
        location: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
      };
    },
    selectTeam(dep, team) {
      let vm = this;
      vm.selected_department = dep;
      vm.getTeamData(team.id);
    },
    getTeamData(team_id) {
      let vm = this;
      vm.loading_team_member = true;
      vm.$store
        .dispatch("ORGANIZATION_GET_TEAM_DATA", {
          id: team_id,
        })
        .then((response) => {
          vm.loading_team_member = false;
          vm.team_data = response.data;
        })
        .catch((error) => {
          vm.loading_team_member = false;
          console.error({ error });
        });
    },
    searchAll(query) {
      let vm = this;
      if (query) {
        vm.$store
          .dispatch("ORGANIZATION_SEARCH_DEPARTMENT_TEAM", { quel: query })
          .then((response) => {
            vm.department_data = response.data.map((item, index) => {
              if (vm.active_department == index) {
                eventBus.$set(item, "expanded", true);
              } else {
                eventBus.$set(item, "expanded", true);
              }
              item.teams = item.teams.map((team) => {
                eventBus.$set(team, "hovered", false);
                eventBus.$set(team, "expanded", true);
                return team;
              });
              return item;
            });
          })
          .catch((error) => {
            console.error({ error });
          });
      } else {
        vm.getTeamDepData();
      }
    },
    removeMember(team_id, member_id) {
      let vm = this;
      vm.$store
        .dispatch("ORGANIZATION_TEAM_REMOVE_MEMBER", {
          id: team_id,
          member_id: member_id,
        })
        .then((response) => {
          vm.getTeamData();
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Member have been removed",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error({ error });
        });
    },
    searchMember(query) {
      let vm = this;
      vm.$store
        .dispatch("ORGANIZATION_SEARCH_TEAM_MEMBER", {
          team_id: vm.team_data.id,
          quel: query,
        })
        .then((response) => {
          vm.team_data.consultants = response.data;
        })
        .catch((error) => {
          console.error({ error });
        });
    },
    memberRowDradStart(e, item, index) {
      let vm = this;
      e.target.className = "";
      e.target.className += "hold";
      vm.dragged_member = item;
      vm.dragged_member_index = index;
    },
    memberRowDragEnd({ target }) {
      let vm = this;
      target.className = "";
    },
    expandSchool(item, index) {
      let vm = this;
      item.expanded = !item.expanded;
      vm.active_department = index;
    },
    memberRowDradDrop(e, team, index) {
      let vm = this;
      e.preventDefault();
    },
    teamRowDradOver(e, team, index) {
      let vm = this;
      e.preventDefault();
      team.hovered = true;
    },
    teamRowDradEnter({ target }, team, index) {
      let vm = this;
      team.hovered = true;
    },
    teamRowDradLeave({ target }, team, index) {
      let vm = this;
      team.hovered = false;
    },
    teamRowDradDrop(e, team, index) {
      let vm = this;
      e.preventDefault();
      team.hovered = false;
      vm.onMemberMove(team.id, vm.dragged_member.id);
    },
    onMemberMove(team_id, member_id) {
      let vm = this;
      vm.$store
        .dispatch("ORGANIZATION_MOVE_TEAM_MEMBER", {
          to_team_id: team_id,
          from_team_id: vm.team_data.id,
          member_id: member_id,
        })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Member have been moved",
            type: "success",
            duration: 3000,
          });
          vm.getTeamDepData();
          vm.getTeamData(vm.team_data.id);
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Something wrong while moving member, Try again",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    depRowDradOver(item, index) {
      let vm = this;
      vm.active_department = index;
      if (vm.$localStorage.get("role") == "team-manager") {
        vm.teams_data = vm.teams_data;
      } else {
        vm.department_data = vm.department_data.map((item) => {
          eventBus.$set(item, "expanded", false);
          return item;
        });
      }
      item.expanded = true;
    },
    depRowDradLeave(item) {
      item.expanded = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$assets: "~@/assets";

.parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .parent-toolbar {
    position: absolute;
    z-index: 999;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .teams-wrapper {
    width: 100%;
    height: calc(100% - 0px);
    display: flex;
    flex-direction: column;

    .teams-widget {
      height: 100%;
      position: relative;

      .custom-resizer {
        height: 100%;

        .teams-list {
          display: flex;
          flex-direction: column;
          height: 100%;
          min-width: calc(100% - 550px);
          width: 100%;
          background: #fff;
          border-radius: 5px;
          position: relative;

          .teams-header {
            height: 50px;
            padding: 10px;

            .team-title-options {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 4px 1px;

              .team-title {
                font-size: 15px;
                margin: 0px;
              }

              .options {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }

            .team-tab {
              display: grid;
              grid-template-columns: 50% 50%;

              .tab {
                padding: 21px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.active {
                  background: #fff;
                  border-bottom: 2px solid #1ba5a4;
                }
              }
            }
          }

          .teams-body {
            height: calc(100% - 60px);
            overflow-y: auto;
            padding-left: 10px;
            padding-right: 10px;

            .departiment-item {
              margin-bottom: 5px;
              background: #f3f3f3;
              border-radius: 5px;
              padding: 0px 1px;

              &.expanded {
                margin-bottom: 15px;
              }

              .department-header {
                align-items: center;
                display: flex;
                border-radius: 5px;
                justify-content: space-between;
                cursor: pointer;
                padding: 8px 10px;
                transition: all 0.3s ease-in-out;

                .start {
                  width: 60%;
                  cursor: pointer;
                  display: flex;
                  align-items: center;

                  .dep-label {
                    color: rgb(95, 95, 95);
                    font-size: 12px;
                    margin-right: 10px;
                  }
                }

                &.active {
                  background: #fff;
                  padding: 12px 10px;
                  transition: all 0.3s ease-in-out;

                  .start {
                    width: 60%;
                    cursor: pointer;

                    .dep-label {
                      color: #1ba5a4;
                      font-size: 14px;
                    }
                  }
                }

                .options {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .class-count-label {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;

                    .arrow-icon {
                      margin-left: 7px;
                    }
                  }
                }
              }

              .department-body {
                padding: 0px 10px;
                padding-top: 5px;
              }
            }

            .table-data {
              border-collapse: collapse;
              border-spacing: 0;

              &.department-table {
                background: transparent !important;
              }

              tr {
                &.row-data {
                  border-bottom: 1px solid #ddd;
                }

                th {
                  &.center {
                    display: flex;
                    align-content: center;

                    input {
                      position: relative;
                      top: 0px;
                      margin-right: 5px;
                      margin: 0px 20px;
                    }
                  }

                  &.text-center {
                    text-align: center;
                  }
                }

                td {
                  text-align: left;
                  font-size: 13px;
                  color: rgb(28, 28, 28);

                  .checkbox {
                    margin: 0px 20px;
                  }

                  .instructor-widget {
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 80px;
                  }

                  .btn-action {
                    padding: 0px;
                    position: relative;

                    img {
                      width: 15px;
                    }
                  }

                  .btn-delete-action {
                    padding: 0px;
                    position: relative;

                    img {
                      width: 18px;
                    }
                  }
                }
              }
            }
          }
        }

        .multipane-resizer {
          width: 20px;
        }

        .teams-details {
          width: 550px;
          min-width: 500px;
          max-width: 550px;
          display: flex;
          flex-direction: column;
          height: 100%;
          background: #fff;
          border-radius: 5px;
          position: relative;
          padding: 10px;

          .team-container-header {
            display: flex;
            flex-direction: column;
            height: 75px;

            .teams-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-top-right-radius: 5px;
              border-top-left-radius: 5px;

              .team-title {
                font-size: 20px;
                margin: 4px;
              }

              .options {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .btn-add {
                  background: #0884af;
                  border-radius: 5px;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 16px;
                }
              }
            }

            .search-add-btn-widget {
              background: #fff;
              padding-top: 10px;
            }
          }

          .team-container-body {
            overflow-y: auto;
            height: calc(100% - 110px);
            padding-bottom: 70px;

            .table-data {
              width: 100%;
              border-collapse: collapse;
              border-spacing: 0;

              .th-checkbox {
                position: relative;
                top: 2px;
              }

              tr {
                border-radius: 10px;
                border-bottom: 1px solid rgb(241, 241, 241);

                &.hold {
                  opacity: 0.3;
                }

                th,
                td {
                  text-align: left;
                }

                td {
                  p {
                    margin: 0px;
                  }

                  .tb-profile-widget {
                    display: flex;
                    align-items: center;
                  }

                  .tb-profile-email {
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                  }
                }
              }
            }
          }

          .empty-widget {
            display: flex;
            align-content: center;
            justify-content: center;

            h1 {
              font-size: 20px;
              color: #ddd;
              margin-top: 200px;
            }
          }
        }
      }
    }

    .form-widget-modal {
      width: 500px;
      min-height: 200px;
      padding: 20px;

      .input-group {
        .terms-widget {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: 0px;
          background: #fff;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          .term-item {
            height: 50px;
            background: #ebeff2;
            color: #696f79;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.active {
              background: #fff;
              color: #1ba5a4;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .dates-widget {
          display: grid;
          grid-template-columns: 49% 48%;
          grid-gap: 10px;
          background: #fff;
          padding: 8px;
        }
      }
    }
  }
}

.search-contact-item {
  padding: 10px 15px;
  display: flex;
  cursor: pointer;
  align-items: center;

  img {
    border-radius: 50%;
    margin-right: 8px;
  }

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.selected-search-contact-item {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;

  img {
    border-radius: 50%;
    margin-right: 8px;
  }

  &:hover {
    background: rgb(250, 250, 250);
  }
}
</style>

<style lang="scss">
.date-field_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.__date-field {
  padding: 1px 5px;
  height: 30px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
}
</style>
