<template>
  <div class="feed-wrapper">
    <div class="school-profile-widget">



      <widgetProfile :title="school.company_info.name" subtitle="" :file_name="school.company_info.logo"
        :path="$fullUrl('images/profiles/')" :size="50" :text_size="17" :show_name="false"></widgetProfile>

      <h1 class="text-2xl">{{ school.company_info.name }}</h1>
      <p class="text-sm mb-4">{{ school.company_info.email }}</p>
      <p class="text-sm mb-4">{{ school.company_info.address }}</p>
      <p class="text-sm mb-4" v-html="school.company_info.description"></p>

      <div class="school-manager-item flex items-center mb-2" v-if="false">
        <widgetProfile title="Meytal Badichi" subtitle="" file_name="" path=""></widgetProfile>
        <div class="flex items-center ml-3">
          <div class=" w-2 h-2 bg-slate-600 rounded-full mr-1"></div> Admin
        </div>
      </div>


      <div class="school-manager-item flex items-center" v-if="false">
        <widgetProfile title="Roy Nevo" subtitle="" file_name="" path=""></widgetProfile>
        <div class="flex items-center ml-3">
          <div class=" w-2 h-2 bg-slate-600 rounded-full mr-1"></div> Instructor
        </div>
      </div>

    </div>
    <div class="hashtag-widget-card">
      <div class="hashtag-header">
        <h1 class="text-lg">Hashtags</h1>
      </div>
      <div class="hashtag-body">
        <div class="hashtag-item" v-for="(item, index) in 5">
          <h5 class="text-lg">#Kigali</h5>
          <widgetDropdown v-model="item.show_dropdown" :right="true" :hover="false" :interactive="true"
            class="selected-dropdown">
            <button class="btn-dropdown">
              <img class="icon" style="height: 20px" src="@/assets/icon/menu-dot.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <app-dialog title="Delete HashTag" ask="Are you sure you want to permanently delete this hashtag."
                yesText="Delete" noText="Cancel" @onComfirm="deleteHashtag(item)">
                <a class="dropdown-item" href="#">Delete</a>
              </app-dialog>
            </div>
          </widgetDropdown>
        </div>
      </div>
      <div class="hashtag-footer">
        4 total hashtags
      </div>
    </div>
    <div class="feed-widget-widget">
      <Posts></Posts>
    </div>
    <div class="ads-widget-widget">
      <div class="ads-item" v-for="(item, index) in 2">
        <div class="add-option">
          <widgetDropdown v-model="item.show_dropdown" :right="true" :hover="false" :interactive="true"
            class="selected-dropdown">
            <button class="btn-dropdown">
              <img class="icon" style="height: 20px" src="@/assets/icon/menu-dot.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#">Edit</a>
              <app-dialog title="Delete HashTag" ask="Are you sure you want to permanently delete this hashtag."
                yesText="Delete" noText="Cancel" @onComfirm="deleteHashtag(item)">
                <a class="dropdown-item" href="#">Delete</a>
              </app-dialog>
            </div>
          </widgetDropdown>
        </div>
        <img src="https://picsum.photos/200">
      </div>
    </div>
  </div>
</template>

<script>
import Posts from "./components/Posts.vue"
export default {
  name: "feed-wrapper",
  components: {
    Posts
  },
  props: {
    school: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => {
    return {

    };
  },
  mounted() {
    let vm = this;

  },
  computed: {

  },
  watch: {

  },
  methods: {
    deleteHashtag(item) { }
  },
};
</script>
<style lang="scss" scoped>
$assets: "~@/assets";

.feed-wrapper {
  display: flex;
  overflow-y: auto;
  height: 100%;

  .school-profile-widget {
    width: 240px;
    max-height: 100%;
    height: max-content;
    background-color: #fff;
    border-radius: 4px;
    margin-right: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 99;
  }

  .hashtag-widget-card {
    width: 200px;
    height: max-content;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 10px;
    position: sticky;
    top: 0px;
    z-index: 99;

    .hashtag-header {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
    }

    .hashtag-body {
      padding: 10px;

      .hashtag-item {
        display: flex;
        justify-content: space-between;
        padding: 5px 0px;

        h5 {
          color: #0884AF;
        }
      }
    }

    .hashtag-footer {
      border-top: 1px solid #f0f0f0;
      padding: 10px;
    }
  }

  .feed-widget-widget {
    width: calc(100% - 650px);
    min-height: 3000px;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 5px;
  }

  .ads-widget-widget {
    width: 300px;
    height: max-content;
    position: sticky;
    top: 0px;
    z-index: 99;


    .ads-item {
      background-color: #fff;
      padding: 6px;
      margin-bottom: 5px;
      border-radius: 5px;
      position: relative;

      img {
        width: 100%;
        height: 180px;
        border-radius: 5px;
      }

      .add-option {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #fff;
        padding-right: 3px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 3px;
      }
    }
  }
}
</style>
