<template>
  <div class="overlay">
    <div class="form-widget-modal section-modal">
      <div class="moder-header">
        <h4 v-if="!section_form.id">Create a new section</h4>
        <h4 v-else>Edit section</h4>

        <app-dialog v-if="section_form.id" title="Delete Lead Section"
          ask="Are you sure you want to delete this lead section?" yesText="Delete" noText="Cancel"
          @onComfirm="deleteSection(section_form.id)">
          <button class="btn-section-delete">
            <img src="@/assets/admin-icon/trush.svg" /> Delete
          </button>
        </app-dialog>


      </div>
      <div class="modal-body">
        <widgetInputWrapper title="Section name*">
          <widgetInput v-model="section_form.section_name" type="text"></widgetInput>
        </widgetInputWrapper>

        <widgetInputWrapper title="Section Description">
          <widgetTextarea v-model="section_form.section_description"></widgetTextarea>
        </widgetInputWrapper>
      </div>
      <div class="modal-footer">
        <c-button class="btn-save" :loading="section_add_loading" @click="submitSection()">Save</c-button>
        <button class="btn-cancel" @click="onCancel">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-modal",
  components: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    team_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      section_form: {
        id: null,
        team_id: null,
        section_name: "",
        section_description: "",
      },
      section_add_loading: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.value) {
      vm.setFieldsValue(vm.value);
    } else {
      vm.resetFormFields();
    }
  },
  watch: {},
  methods: {
    setFieldsValue(item) {
      let vm = this;
      vm.section_form.id = item.id;
      vm.section_form.section_name = item.name;
      vm.section_form.section_description = item.description;
    },
    resetFormFields() {
      let vm = this;
      vm.section_form.id = null;
      vm.section_form.section_name = "";
      vm.section_form.section_description = "";
    },
    submitSection() {
      let vm = this;
      const rules = {
        section_name: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Section name can not be empty";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.section_form, rules)) {
        vm.section_add_loading = true;
        if (!vm.section_form.id) {
          vm.$store
            .dispatch("LEADS_ADD_NEW_SECTION", vm.section_form)
            .then((response) => {
              vm.section_add_loading = false;
              vm.show_section_form = false;
              vm.$emit("saved");
            })
            .catch((error) => {
              vm.section_add_loading = false;
            });
        } else {
          vm.$store
            .dispatch("LEADS_UPDATE_SECTION", vm.section_form)
            .then((response) => {
              vm.section_add_loading = false;
              vm.show_section_form = false;
              vm.$emit("saved");
            })
            .catch((error) => {
              vm.section_add_loading = false;
            });
        }
      }
    },
    deleteSection(id) {
      let vm = this;
      vm.$emit("delete", id);
    },
    onCancel() {
      this.$emit("close");
    },
  },
  computed: {},
  created() { },
};
</script>
<style lang="scss" scoped>
.form-widget-modal {
  width: 500px;
  position: relative;

  &.section-modal {
    .moder-header {
      display: flex;
      justify-content: space-between;

      .btn-section-delete {
        display: flex;
        align-items: center;

        img {
          width: 12px;
          margin-right: 3px;
          margin-top: -2px;
        }
      }
    }
  }

  .input-group {
    position: relative;

    .task-search-reasult {
      position: absolute;
      height: 200px;
      overflow-y: auto;
      z-index: 9999;
      background: #fff;
      box-shadow: 0px 0px 10px #a8a8a8;
      width: 100%;
      top: 62px;

      .task-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background: rgb(241, 241, 241);
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}

.search-account-item {
  padding: 10px 15px;
  display: block;
  cursor: pointer;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.selected-search-account-item {
  padding: 5px 12px;
  display: block;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.input-date {
  background: #fff;
}
</style>
