<template>
  <div class="parent-container">
    <div class="parent-toolbar">
      <WidgetToolbar title="School" :icon="require('@/assets/admin-icon/menu/school_active.svg')">
        <div class="options">
          <div class="row">
            <widgetSearch icon="right" v-if="tab == 'all'" v-model="search_project_input"
              @keyup="searchCompanyProject()" placeholder="Type in to Search…"></widgetSearch>
            <widgetSearch icon="right" v-if="tab == 'our'" v-model="search_project_input"
              @keyup="searchCompanyOurProject()" placeholder="Type in to Search…"></widgetSearch>

            <div v-if="checked_project.length > 0" class="v-line-divider" style="height: 40px"></div>
            <widgetButton :loading="false" class="btn-secondary" v-if="checked_project.length > 0"
              @click="addSelectedToPublic()">
              Add To Public
            </widgetButton>
          </div>
        </div>
      </WidgetToolbar>
    </div>
    <div class="company-profile-wrapper">
      <!-- company-profile display -->
      <div class="company-profile-container" v-animate-css="'fadeIn'">
        <multipane class="custom-resizer" layout="vertical">
          <div class="items">
            <div class="company-profile-body">
              <div class="tabs">
                <div class="tab" :class="{ active: tab == 'all' }" @click="tab = 'all'">
                  All projects
                </div>
                <div class="tab" :class="{ active: tab == 'our' }" @click="tab = 'our'">
                  School projects
                </div>
              </div>

              <div class="project-widget" v-if="is_items_list_open">
                <!--  All Project list -->
                <ul class="project-items" v-if="user.projects.length > 0 && tab == 'all'">
                  <li class="project-item" v-for="(item, i) in user.projects" :class="{ active: item.active }" :key="i">
                    <div class="project-item-header" @click="viewItem(item)">
                      <input type="checkbox" @change="onCheckedproject($event, item.id)" class="checkbox" />
                      <div class="project-item-brand">
                        <img src="@/assets/icons/project-icon.svg" />
                      </div>
                      <div class="content">
                        <div class="title-row">
                          <label>{{ item.name | validate }}</label>
                          <span v-if="item.address">{{
                            item.address | validate
                            }}</span>

                          <span class="date">
                            <span v-if="item.from">{{
                              item.from | formatDate | validate
                              }}</span>
                            <span class="separator">-</span>
                            <span v-if="item.to">{{
                              item.to | formatDate | validate
                              }}</span>
                            <span v-else>Current</span>
                          </span>
                        </div>
                        <div class="options">
                          <div class="btns">
                            <dropdown-menu>
                              <ul>
                                <li v-if="!item.is_public">
                                  <a @click.prevent="addToPublic(item.id)" href>Add To Public</a>
                                </li>
                                <li v-else>
                                  <a @click.prevent="removeToPublic(item.id)" href>Remove From Public</a>
                                </li>
                                <li>
                                  <a @click.prevent="deleteProject(item.id, i)" href>Delete Project</a>
                                </li>
                              </ul>
                            </dropdown-menu>
                          </div>
                          <!-- <button
                          v-if="!item.verified"
                          class="btn-verify"
                          @click="showVerifyForm(item.id)"
                        >Verify project</button>-->
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="empty-widget" v-if="user.projects.length == 0 && tab == 'all'" style="padding-top: 150px">
                  <widgetNotFoundWidget title="Looks like you haven’t added any project yet">
                    <img class="widget-img" src="@/assets/admin-icon/add-student-icon.svg" />
                  </widgetNotFoundWidget>
                </div>
                <!-- List of our project -->
                <ul class="project-items" v-if="user.our_projects.length > 0 && tab == 'our'">
                  <li class="project-item" v-for="(item, i) in user.our_projects" :class="{ active: item.active }"
                    :key="i">
                    <div class="project-item-header" @click="viewItem(item)">
                      <div class="project-item-brand">
                        <img src="@/assets/icons/project-icon.svg" />
                      </div>
                      <div class="content">
                        <div class="title-row">
                          <label>{{ item.name | validate }}</label>
                          <span v-if="item.address">{{
                            item.address | validate
                            }}</span>

                          <span class="date">
                            <span v-if="item.from">{{
                              item.from | formatDate | validate
                              }}</span>
                            <span class="separator">-</span>
                            <span v-if="item.to">{{
                              item.to | formatDate | validate
                              }}</span>
                            <span v-else>Current</span>
                          </span>
                        </div>
                        <div class="options">
                          <div class="btns">
                            <dropdown-menu>
                              <ul>
                                <li v-if="!item.is_public">
                                  <a @click.prevent="addToPublic(item.id)" href>Add To Public</a>
                                </li>
                                <li v-else>
                                  <a @click.prevent="removeToPublic(item.id)" href>Remove From Public</a>
                                </li>
                                <li>
                                  <a @click.prevent="deleteProject(item.id, i)" href>Delete Project</a>
                                </li>
                              </ul>
                            </dropdown-menu>
                          </div>
                          <!-- <button
                          v-if="!item.verified"
                          class="btn-verify"
                          @click="showVerifyForm(item.id)"
                        >Verify project</button>-->
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="empty-widget" v-if="user.our_projects.length == 0 && tab == 'our'">
                  <div class="empty-widget-body" style="padding-top: 150px">
                    <widgetNotFoundWidget title="Looks like you haven’t added any school project yet">
                      <img class="widget-img" src="@/assets/admin-icon/add-student-icon.svg" />
                    </widgetNotFoundWidget>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <multipane-resizer></multipane-resizer>
          <div class="item-view-more" :style="{ flexGrow: 1 }" v-if="project_item">
            <div class="project-item">
              <div class="project-item-header">
                <div class="project-item-brand">
                  <img src="@/assets/icons/project-icon.svg" alt />
                </div>
                <div class="content">
                  <div class="title-row">
                    <label>{{ project_item.name | validate }}</label>
                    <span v-if="project_item.address">{{
                      project_item.address | validate
                      }}</span>

                    <span class="date">
                      <span v-if="project_item.from">{{
                        project_item.from | formatDate | validate
                        }}</span>
                      <span class="separator">-</span>
                      <span v-if="project_item.to">{{
                        project_item.to | formatDate | validate
                        }}</span>
                      <span v-else>Current</span>
                    </span>
                  </div>
                  <button class="close-btn" @click="showItemsList()">
                    <span>&times;</span>
                  </button>
                  <div class="options">
                    <!-- <button
                          v-if="!project_item.verified"
                          class="btn-verify"
                          @click="showVerifyForm(project_item.id)"
                        >Verify project</button>-->
                  </div>
                </div>
              </div>
              <div class="project-item-body">
                <div class="project-info">
                  <h5 v-if="project_item.company">Company</h5>
                  <p v-if="project_item.company">
                    {{ project_item.company.name | validate }}
                  </p>
                  <h5 v-if="project_item.description">Description</h5>
                  <p class="disc" v-html="project_item.description"></p>
                  <h5 v-if="project_item.members && project_item.members.length > 0
                  ">
                    Members
                  </h5>
                  <div class="collaboraters-widget" v-if="project_item.members && project_item.members.length > 0
                  ">
                    <div class="item" v-for="(item, i) in project_item.members" :key="i">
                      <img v-if="project_item.profile_picture" class="profile-img" :src="$fullUrl(
                        'images/profiles/' + project_item.profile_picture
                      )
                        " alt />
                      <img class="profile-img" v-else src="@/assets/icons/logo_avatar.svg" alt />
                      <span class="name">{{ project_item.first_name | validate }}
                        {{ project_item.last_name | validate }}</span>
                    </div>
                  </div>
                  <h5>Skills</h5>
                  <div class="skills-widget" v-if="project_item.skills">
                    <div class="skills_content__wrapper">
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Tools</label>
                        </div>
                        <div class="skill_box_body" v-if="project_item.skills.tools.length > 0">
                          <div class="row__item" v-for="(
                                value, i
                              ) in project_item.skills.tools.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                              :star-size="14" :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any tool yet.</p>
                          </div>
                        </div>
                      </div>
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Domains</label>
                        </div>
                        <div class="skill_box_body" v-if="project_item.skills.domains.length > 0">
                          <div class="row__item" v-for="(
                                value, i
                              ) in project_item.skills.domains.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                              :star-size="14" :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any domain yet.</p>
                          </div>
                        </div>
                      </div>
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Languages</label>
                        </div>
                        <div class="skill_box_body" v-if="project_item.skills.languages.length > 0">
                          <div class="row__item" v-for="(
                                value, i
                              ) in project_item.skills.languages.slice(0, 5)" :key="i">
                            <span>{{ value.lang.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                              :star-size="14" :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any language yet.</p>
                          </div>
                        </div>
                      </div>

                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Roles</label>
                        </div>
                        <div class="skill_box_body" v-if="project_item.skills.roles.length > 0">
                          <div class="row__item" v-for="(
                                value, i
                              ) in project_item.skills.roles.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                              :star-size="14" :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any role yet.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="next_skills_content__wrapper">
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Competences</label>
                        </div>
                        <div class="skill_box_body" v-if="project_item.skills.competences.length > 0">
                          <div class="row__item" v-for="(
                                value, i
                              ) in project_item.skills.competences.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                              :star-size="14" :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any competence yet.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- When no item selected -->
          <div class="item-view-more" :style="{ flexGrow: 1 }" v-else>
            <widgetNotFoundWidget title="No Project Selected" content="Please select a project to view more details."
              align="center">
              <img class="widget-img" src="@/assets/icons/member-notfound.svg" />
            </widgetNotFoundWidget>
          </div>
        </multipane>
      </div>
      <!-- End -->

      <!-- Add and update form -->
      <div class="add-update-form" v-if="add_update_form">
        <div class="form">
          <div class="project-form">
            <h2 v-if="!newProject.id">Add New Project</h2>
            <h2 v-else>Edit Project</h2>
            <form @submit.prevent="saveProject('add-project')" data-vv-scope="add-project">
              <div class="form-group">
                <div class="__box">
                  <span>Project Name</span>

                  <input type="text" v-model="newProject.name" placeholder="E.g.  Perfomance Boosting"
                    name="project_name" v-validate="'required'" />
                  <span class="error_alert" v-if="errors.has('add-project.project_name')">Project name is
                    required</span>
                </div>
                <div style="width: 10px"></div>
                <div class="__box">
                  <div style="display: flex; justify-content: space-between">
                    <span>
                      Time Frame
                      <b>*</b>
                    </span>
                    <div style="display: flex">
                      <label for="now">Current Project</label>
                      <input type="checkbox" id="now" style="margin-top: 5px; margin-left: 5px"
                        v-model="datepickers.picker2" @change="setCurrentTime('new-project')" />
                    </div>
                  </div>
                  <div class="grouped" :class="{ disabling: datepickers.picker2 }">
                    <datepicker :monday-first="true" placeholder="From" v-model="newProject.from"
                      input-class="__date-field" wrapper-class="__date-field" name="newproject_from_date"
                      v-validate="'required'"></datepicker>
                    <div style="width: 5px"></div>
                    <datepicker :monday-first="true" placeholder="To" v-model="newProject.to"
                      :disabled="datepickers.picker2 || newProject.from === ''"
                      :disabled-dates="{ to: newProject.from }" input-class="__date-field2"
                      wrapper-class="__date-field2" name="newproject_to_date"></datepicker>
                  </div>
                  <span class="error_alert" v-if="errors.has('add-project.newproject_from_date')">Project date is
                    required</span>
                </div>
              </div>
              <div class="__box b__20">
                <span>
                  Company
                  <b>*</b>
                </span>
                <input type="text" placeholder="E.g.  Tesla" v-model="newProject.company" class="__textview"
                  ref="searchMember" v-validate="'required'" name="company" />
                <span class="error_alert" v-if="errors.has('add-project.company')">Project company is required</span>
              </div>
              <div class="__box">
                <span>Description</span>
                <html-editor v-model="newProject.description" id="project-descr" />
              </div>

              <div class="form-group" style="flex-direction: column">
                <span style="margin-top: 24px; margin-bottom: 1px">
                  Location
                  <b>*</b>
                </span>
                <div style="display: flex">
                  <div class="__box">
                    <select class="__textview" v-model="newProject.country" @change="loadCities" v-validate="'required'"
                      name="country">
                      <option value="0">Country</option>
                      <option v-for="(country, index) in getCountries.countries" :value="country.id" :key="index">
                        {{ country.name | validate }}
                      </option>
                    </select>
                    <span class="error_alert" v-if="errors.has('add-project.country')">Project country is
                      required</span>
                  </div>
                  <div style="width: 10px"></div>
                  <div class="__box">
                    <select class="__textview" v-model="newProject.city">
                      <option value="0">City</option>
                      <option v-for="(city, index) in getCities" :key="index" :value="city.id">
                        {{ city.name | validate }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="__box">
                <span class="helper-widget">
                  <label>Project Members</label>
                  <help-tool-tip
                    text="Members will be added to the project as soon as they have confirmed the invitation." />
                </span>
                <div class="input__dropdown">
                  <div class="dropdown__wrapper">
                    <div class="invite-widget">
                      <input type="text" placeholder="Search for project members…" v-model="newProject.member"
                        class="__textview" ref="searchMember" />
                      <label>Or invite them via Email</label>
                      <button class="btn-invite-via-email" @click.prevent="(e) => {
                        invitation.target = newProject.member;
                        showAddProjectInviteModel = true;
                      }
                        ">
                        Invite via Email
                      </button>
                    </div>
                    <span class="help-widget-absolute">
                      <help-tool-tip position="top left"
                        text="This button provides the possibility to add project members not registered on Meritios." />
                    </span>

                    <div v-show="getSearchedPeople && getSearchedPeople.length" class="dropdown__body">
                      <div class="dropdown__item" v-for="(person, i) in getSearchedPeople" :key="i + 'xzhjbh'" @click="(e) => {
                        addProjectMate(person), newProject.member;
                      }
                        ">
                        <img v-if="person.profile_picture" :src="$fullUrl(
                          'images/profiles/' + person.profile_picture
                        )
                          " alt="picture" />
                        <img v-else src="@/assets/avatar.png" alt />
                        <div class="text">
                          <span>{{
                            (person.first_name + " " + person.last_name)
                            | validate
                            }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Added member list -->
                <div class="__people" v-show="newProject.people.length > 0">
                  <div class="person__item" v-for="(member, i) in newProject.people" :key="i + 'sdjks'">
                    <div class="person_avatar">
                      <img v-if="member.picture" :src="$fullUrl('images/profiles/' + member.picture)" alt />
                      <img v-else src="@/assets/avatar.png" alt />
                      <span class="person_name">{{
                        member.names | validate
                        }}</span>
                    </div>
                    <div class="__close" @click="() => {
                      newProject.people.splice(i, 1);
                    }
                      ">
                      <img src="@/assets/icons/close.svg" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="__box skills-input-widget">
                <span class="__m20">Skills</span>
                <skillsInput v-model="newProject.skills" />
              </div>

              <div class="form-group">
                <div class="__box">
                  <span>Hourly Rate</span>
                  <input type="text" v-model="newProject.hourly_rate" placeholder="USD" class="__textview" />
                </div>
                <div style="width: 10px"></div>
                <div class="__box">
                  <span>
                    Job Title
                    <b>*</b>
                  </span>
                  <input type="text" v-model="newProject.role" placeholder="Eg : Project Manager" class="__textview"
                    v-validate="'required'" name="role" />
                  <span class="error_alert" v-if="errors.has('add-project.role')">Project role is required</span>
                </div>
              </div>
              <div class="__btns">
                <consultfied-button :loading="save_project_loading" @click="submitProject()"
                  class="btn-save">Save</consultfied-button>
                <button class="btn-cancel" @click="closeAddUpdateForm()">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Verify form -->
      <div class="verify-form" v-show="show_verify">
        <div class="project-form">
          <div>
            <h2 class="__green">Project Verification</h2>
          </div>
          <div class="__cardsss">
            <div class="__box">
              <div class="__row-1">
                <span class="__label">Company Domain</span>
              </div>
              <div class="input-group">
                <input type="text" v-model="request.domain" placeholder="E.g.  meritios.com" class />
              </div>
            </div>
            <div class="__box">
              <div class="__row-1">
                <span class="__label">Company Email</span>
              </div>
              <div class="input-group">
                <input type="text" v-model="request.email" placeholder="E.g.  johndoe@meritios.com" class />
              </div>
            </div>
            <div class="__btns">
              <consultfied-button :loading="verify_project_loading" @click="verifyProject()" class="save">Send a
                request</consultfied-button>
              <button class="cancel" @click="closeVerifyForm()">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Invite via email form -->
      <template>
        <v-layout justify-center style="z-index: 99999; border-radius: 5px; overflow: hidden">
          <v-dialog v-model="showAddProjectInviteModel" persistent max-width="500" style="border-radius: 5px">
            <v-card color="#D9D9D9">
              <v-layout>
                <div class="card__body">
                  <div class="__layout">
                    <div class="div">
                      <label>Email address</label>
                      <input type="text" placeholder="E.g. abcd@youremail.com" v-model="invitation.email" />
                    </div>
                    <div class="div">
                      <label>Message</label>
                      <div class="msg">
                        Dear Sir/Madam,
                        <br />
                        <br />I have invited you as a member of a project.
                        Please get back to me to confirm your participation to
                        the project through the link below.
                        <br />
                        <br />Kind regards,
                        <br />
                        <br />User name
                      </div>
                    </div>
                    <div class="btns-2">
                      <consultfied-button :loading="envitation_project_loading" @click="sendProjectInvitation()"
                        class="save">Send</consultfied-button>
                      <button class="cancel" @click="showAddProjectInviteModel = false">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </v-layout>
            </v-card>
          </v-dialog>
        </v-layout>
      </template>
    </div>
  </div>
</template>

<script>
import consultfiedButton from "@/components/helper/consultfiedButton.vue";
import Datepicker from "vuejs-datepicker";
import skillsInput from "@/components/Partials/skillsInput.vue";
import HelpToolTip from "@/components/helper/HelpToolTip.vue";
import { eventBus } from "@/main";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "projects",
  components: {
    consultfiedButton,
    Datepicker,
    skillsInput,
    HelpToolTip,
  },
  props: {},
  data: () => {
    return {
      tab: "all",
      user: {
        id: null,
        company_info: {},
        manager_info: {},
        manager_profile: {},
        consultants: [],
        projects: [],
        our_projects: [],
      },
      add_update_form: false,
      is_items_list_open: true,
      show_verify: false,
      showAddProjectInviteModel: false,
      save_project_loading: false,
      datepickers: {
        picker1: false,
        picker2: false,
        picker3: false,
        picker4: false,
      },
      invitation: {
        target: "",
        email: "",
        note: "",
        type: "",
        id: 0,
      },
      newProject: {
        id: null,
        skills: {},
        name: "",
        company: "",
        from: "",
        to: "",
        country: 0,
        city: 0,
        address: "",
        description: "",
        hourly_rate: "",
        member: "",
        langs: [],
        langNames: [],
        members: [],
        people: [],
        invites: [],
        show: false,
        roles: [],
        role: "",
      },
      request: {
        domain: "",
        email: "",
      },
      envitation_project_loading: false,
      verify_project_loading: false,
      search_project_input: "",
      project_item: null,
      checked_project: [],
    };
  },
  mounted() {
    let vm = this;
    vm.getCompanyData();
    vm.$store.dispatch("GET_COUNTRIES");
  },
  computed: {
    ...mapGetters(["getCountries", "getCities", "getSearchedPeople"]),
  },
  methods: {
    addSelectedToPublic() {
      let vm = this;
      vm.$store
        .dispatch("COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC", {
          projects: vm.checked_project,
        })
        .then((responce) => {
          vm.checked_project = [];
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Now projects added to public profile",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Warning",
            text: "Failed, Try again",
            type: "warn",
            duration: 3000,
          });
          console.log(error);
        });
    },
    onCheckedproject({ target }, project_id) {
      let vm = this;
      if (target.checked) {
        if (!vm.checked_project.includes(project_id)) {
          vm.checked_project.push(project_id);
        }
      } else {
        const index = vm.checked_project.indexOf(project_id);
        if (index > -1) {
          vm.checked_project.splice(index, 1);
        }
      }
    },
    viewItem(item) {
      let vm = this;
      vm.user.projects.forEach((item) => {
        eventBus.$set(item, "active", false);
        return item;
      });
      vm.user.our_projects.forEach((item) => {
        eventBus.$set(item, "active", false);
        return item;
      });
      item.active = true;
      vm.project_item = item;
    },
    removeToPublic(id) {
      let vm = this;
      vm.$store
        .dispatch("COMPANY_REMOVE_PROJECT_TO_PUBLIC", { id: id })
        .then((responce) => {
          vm.getCompanyData();
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Now project was removed to public profile",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Warning",
            text: "Project was not removed to public profile,Try again",
            type: "warn",
            duration: 3000,
          });
          console.log(error);
        });
    },
    addToPublic(id) {
      let vm = this;
      vm.$store
        .dispatch("COMPANY_ADD_PROJECT_TO_PUBLIC", { id: id })
        .then((responce) => {
          vm.getCompanyData();
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Now project was added to public profile",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Warning",
            text: "Project was not added to public profile,Try again",
            type: "warn",
            duration: 3000,
          });
          console.log(error);
        });
    },
    searchCompanyOurProject() {
      let vm = this;
      let query = vm.search_project_input;
      if (vm.search_project_input) {
        vm.$store
          .dispatch("SEARCH_COMPANY_OUR_PROJECT", { query: query })
          .then((responce) => {
            let projects_data = vm.processSkills(responce.data);
            eventBus.$set(vm.user, "our_projects", projects_data);
            vm.user.our_projects.forEach((item) => {
              eventBus.$set(item, "active", false);
              return item;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        vm.getCompanyData();
      }
    },
    searchCompanyProject() {
      let vm = this;
      let query = vm.search_project_input;
      if (vm.search_project_input) {
        vm.$store
          .dispatch("SEARCH_COMPANY_PROJECT", { query: query })
          .then((responce) => {
            let projects_data = vm.processSkills(responce.data);
            eventBus.$set(vm.user, "projects", projects_data);
            vm.user.projects.forEach((item) => {
              eventBus.$set(item, "active", false);
              return item;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        vm.getCompanyData();
      }
    },
    verifyProject(id) {
      let vm = this;
      eventBus.$set(vm.request, "user_id", vm.id);
      vm.verify_project_loading = true;
      vm.$store
        .dispatch("COMPANY_CONSULTANT_VERIFY_PROJECT", vm.request)
        .then((responce) => {
          vm.closeVerifyForm();
          vm.verify_project_loading = false;
        })
        .catch((error) => {
          vm.verify_project_loading = false;
          console.log({ error });
        });
    },
    sendProjectInvitation() {
      let vm = this;
      vm.showAddProjectInviteModel = false;
    },
    deleteProject(id, i) {
      let vm = this;
      vm.$store
        .dispatch("COMPANY_CONSULTANT_DELETE_PROJECT", {
          id: id,
          user_id: vm.id,
        })
        .then((responce) => {
          vm.getCompanyData();
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    moment,
    editProject(item) {
      let vm = this;
      vm.add_update_form = true;
      vm.is_items_list_open = false;
      // fields
      vm.newProject.id = item.id;
      vm.newProject.skills = item.skills;
      vm.newProject.name = item.name;
      vm.newProject.company = item.company.name;
      vm.newProject.from = item.from ? vm.moment(item.from) : "";
      vm.newProject.to = item.to ? vm.moment(item.to) : "";
      vm.newProject.country = item.location.country.id || 0;
      vm.$store.dispatch("GET_CITIES", {
        country: item.location.country.id || 0,
      });
      vm.newProject.city = item.location.city.id || 0;
      vm.newProject.address = item.address;
      vm.newProject.description = item.description;
      vm.newProject.hourly_rate = item.hourly_rate;
      //vm.newProject.member = item.members;
      vm.newProject.langs = [];
      vm.newProject.langNames = [];
      vm.newProject.members = [];
      vm.newProject.people = [];
      vm.newProject.invites = [];
      vm.newProject.roles = [];
      vm.newProject.role = item.role;
    },
    submitProject() {
      let vm = this;
      vm.save_project_loading = true;
      eventBus.$set(vm.newProject, "user_id", vm.id);
      if (!vm.newProject.id) {
        // Add new project
        vm.$store
          .dispatch("COMPANY_CONSULTANT_ADD_NEW_PROJECT", vm.newProject)
          .then((responce) => {
            vm.save_project_loading = false;
            vm.getConsultantData(vm.id);
          })
          .catch((error) => {
            vm.save_project_loading = false;
            console.log({ error });
          });
      } else {
        // Update project
        vm.$store
          .dispatch("COMPANY_CONSULTANT_UPDATE_PROJECT", vm.newProject)
          .then((responce) => {
            vm.save_project_loading = false;
            vm.getConsultantData(vm.id);
          })
          .catch((error) => {
            vm.save_project_loading = false;
            console.log({ error });
          });
      }
    },
    getCompanyData() {
      let vm = this;
      vm.$store
        .dispatch("GET_ME_USER_COMPANY")
        .then((responce) => {
          vm.user = responce.data;
          let projects_data = vm.processSkills(vm.user.projects);
          if (window.innerWidth > 500) {
            if (vm.user.projects.length > 0) {
              vm.viewItem(projects_data[0]);
            }
          }
          eventBus.$set(vm.user, "projects", projects_data);
          let our_projects_data = vm.processSkills(vm.user.our_projects);
          eventBus.$set(vm.user, "our_projects", our_projects_data);
          vm.user.projects.forEach((item) => {
            eventBus.$set(item, "active", false);
            return item;
          });
          vm.user.our_projects.forEach((item) => {
            eventBus.$set(item, "active", false);
            return item;
          });
        })
        .catch((error) => { });
    },
    processSkills(obj) {
      return obj.map((item) => {
        let skills = {
          tools: [],
          domains: [],
          languages: [],
          competences: [],
          roles: [],
        };
        if (item.skills.length && item.skills.length > 0) {
          item.skills.forEach((skill) => {
            skill.tools.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.tools.push(obj);
            });
            skill.domains.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.domains.push(obj);
            });
            skill.competences.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.competences.push(obj);
            });
            skill.roles.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.roles.push(obj);
            });
            skill.languages.forEach((item) => {
              if (item.lang) {
                let obj = {
                  lang: { name: item.lang.name },
                  level: parseInt(item.level),
                };
                skills.languages.push(obj);
              }
            });
          });
        }
        eventBus.$set(item, "skills", skills);
        return item;
      });
    },
    loadCities(e) {
      this.$store.dispatch("GET_CITIES", { country: e.target.value });
    },
    addUpdateForm() {
      let vm = this;
      vm.add_update_form = true;
      vm.is_items_list_open = false;
      vm.newProject = {
        id: null,
        skills: {},
        name: "",
        company: "",
        from: "",
        to: "",
        country: 0,
        city: 0,
        address: "",
        description: "",
        hourly_rate: "",
        member: "",
        langs: [],
        langNames: [],
        members: [],
        people: [],
        invites: [],
        show: false,
        roles: [],
        role: "",
      };
    },
    closeAddUpdateForm() {
      let vm = this;
      vm.add_update_form = false;
      vm.is_items_list_open = true;
    },
    showItemsList() {
      let vm = this;
      vm.add_update_form = false;
      vm.is_items_list_open = true;
      vm.project_item = null;
    },
    showVerifyForm(id) {
      let vm = this;
      vm.show_verify = true;
      vm.is_items_list_open = false;
      eventBus.$set(vm.request, "project_id", id);
    },
    closeVerifyForm() {
      let vm = this;
      vm.show_verify = false;
      vm.is_items_list_open = true;
    },
  },
};
</script>
<style lang="scss" scoped>
$assets: "~@/assets";

.parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .parent-toolbar {
    position: absolute;
    z-index: 999;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .company-profile-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .company-profile-container {
      padding: 1px;
      width: 100%;
      position: relative;
      height: 100%;
      display: flex;

      .custom-resizer {
        height: 100%;

        .items {
          height: 100%;
          min-width: 50%;
          width: 50%;
          background: #fff;
          border-radius: 10px;
          display: flex;
          flex-direction: column;

          @media (min-width: 300px) and (max-width: 630px) {
            background: #fff;
          }

          .company-profile-body {
            height: 100%;
            border-radius: 5px;
            display: flex;
            flex-direction: column;

            .tabs {
              display: grid;
              grid-template-columns: 50% 50%;

              .tab:nth-child(1) {
                border-top-left-radius: 5px;
              }

              .tab:nth-child(2) {
                border-top-right-radius: 5px;
              }

              .tab {
                background: #cccccc;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: 500ms ease-in-out;
                color: #fff;

                &.active {
                  background: #fff;
                  color: #1ba5a4;

                  transition: 500ms ease-in-out;
                }

                &:hover {
                  color: #1ba5a4;
                }
              }
            }

            .project-widget {
              overflow-y: auto;
              height: calc(100% - 55px);

              ul.project-items {
                background: #ffffff;
                padding: 20px 20px;
                list-style: none;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                margin: 0px;

                .project-item {
                  background: #f5f5f5 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  margin-bottom: 5px;
                  position: relative;
                  cursor: pointer;

                  :hover {
                    background: rgb(248, 248, 248);
                    border-radius: 10px;
                    cursor: pointer;

                    .checkbox {
                      display: block !important;
                    }
                  }

                  &.active {
                    border: 1px solid #1ba5a4;
                    background: rgb(248, 248, 248);
                  }

                  .project-item-header {
                    display: grid;
                    padding: 5px 10px;
                    grid-template-columns: 50px calc(97% - 50px);
                    grid-column-gap: 10px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      grid-template-columns: 100%;
                      grid-column-gap: 10px;
                      padding: 10px 12px;
                    }

                    position: relative;

                    .checkbox {
                      position: absolute;
                      display: none;
                    }

                    .checkbox:checked {
                      display: block;
                    }

                    .project-item-brand {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;

                      @media (min-width: 300px) and (max-width: 630px) {
                        width: 40px;
                        height: 40px;
                        display: none;
                      }

                      img {
                        width: 35px;
                        height: 35px;
                        object-fit: contain;

                        @media (min-width: 300px) and (max-width: 630px) {
                          width: 33px;
                          height: 33px;
                        }
                      }
                    }

                    .content {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .title-row {
                        padding-right: 9px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: calc(100% - 50px);

                        label {
                          display: block;
                          font-size: 12px;
                          margin-bottom: 2px;
                          overflow: hidden;
                          text-decoration: none;
                          text-overflow: ellipsis;
                          white-space: nowrap;

                          @media (min-width: 300px) and (max-width: 630px) {
                            font-size: 12px;
                          }
                        }

                        span {
                          color: #808080;
                          font-size: 11px;

                          @media (min-width: 300px) and (max-width: 630px) {
                            font-size: 11px;
                          }
                        }

                        .date {
                          color: #808080;
                          font-size: 11px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          margin-top: 2px;

                          .separator {
                            margin: 0px 5px;
                            position: relative;
                            top: -3px;
                          }

                          @media (min-width: 300px) and (max-width: 630px) {
                            font-size: 11px;
                          }
                        }
                      }

                      .options {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        width: 10px;

                        .btns {
                          display: flex;
                          margin-top: px;
                        }

                        .verified-status {
                          color: #0590ab;
                          margin: 10px 0px;
                        }

                        .btn-verify {
                          height: 40px;
                          width: 100px;
                          outline: none;
                          color: #fff;
                          background-color: #1890a9;
                          border-radius: 5px;
                        }

                        .status {
                          color: #000;

                          font-size: 13px;
                        }

                        .toogle-panel-btn {
                          width: 19px;
                          padding: 0;
                          height: 22px;
                          font-size: 20px;
                          position: absolute;
                          bottom: 10px;
                          right: 3px;

                          @media (min-width: 300px) and (max-width: 630px) {
                            right: 10px;
                          }

                          img {
                            width: 15px;
                          }
                        }
                      }
                    }

                    .toogle-panel-btn {
                      padding: 1px 40px;
                      font-size: 30px;
                      background: transparent;
                      border: none;
                      outline: none;
                      cursor: pointer;
                      color: #ccc;
                    }
                  }
                }
              }

              .empty-widget {
                background: #fff;
                padding: 20px;

                .empty-widget-body {
                  height: 350px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  p {
                    text-align: center;
                    width: 270px;
                  }

                  .add-btn {
                    width: 180px;
                    height: 45px;
                    background: #1890a9 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    color: #fff;
                    outline: none;
                    margin-right: 20px;
                    display: center;
                    justify-content: center;
                  }
                }
              }
            }

            .add-update-form {
              background: #fff;
              padding: 20px;
              border-radius: 5px;

              .form {
                background: #f7f7f7;
                padding: 20px;
                border-radius: 5px;

                h2 {
                  font-family: "Nunito", sans-serif;
                  font-size: 13px;
                  font-weight: 900;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 3.77;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000000;
                }

                span {
                  font-size: 14px;
                  margin-left: 10px;
                  margin-bottom: 10px;
                  color: #646262;
                }

                .__row {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 15px;

                  h2 {
                    color: #000000;
                    font-weight: normal;
                    line-height: 0px;
                    font-size: 22px;
                  }

                  button {
                    background: transparent;
                    outline: none;
                    color: #680404;
                    width: 94px;
                    border-radius: 5px;
                    border: 0.5px solid #7e0505;
                    height: 27px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      margin-left: 20px;
                    }
                  }
                }

                .skills-input-widget {
                  margin-bottom: 20px;
                }

                .form-group {
                  display: flex;
                  justify-content: space-between;

                  @media (min-width: 300px) and (max-width: 630px) {
                    flex-direction: column;
                  }

                  .__box {
                    max-width: 50%;
                    width: 50%;
                    display: flex;
                    flex-direction: column;

                    @media (min-width: 300px) and (max-width: 630px) {
                      max-width: 100%;
                      width: 100%;
                    }

                    span {
                      font-family: "Nunito", sans-serif;
                      font-size: 14px;
                      font-weight: 300;
                      font-stretch: normal;
                      font-style: normal;
                      letter-spacing: normal;
                      text-align: left;
                      color: #646262;

                      b {
                        font-weight: 900;
                        color: #0884af;
                      }
                    }

                    .grouped,
                    input[type="text"],
                    textarea,
                    select {
                      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
                      background: #fff;
                      font-size: 14px;
                      resize: none;
                      display: flex;
                      margin-bottom: 20px;
                      justify-content: space-between;
                      width: 100%;
                      padding: 10px;
                      outline: none;
                      border-radius: 5px;
                      border: none;
                      color: #707070;

                      @media (min-width: 300px) and (max-width: 630px) {
                        margin-bottom: 4px;
                      }

                      .__date-field,
                      .__date-field2 {
                        background-color: #f4f4f4;
                        background-image: url($assets + "/icons/calendar__icon.svg");
                        background-repeat: no-repeat;
                        background-position: right;
                        width: 49%;
                        border-radius: 5px;
                        padding: 5px;
                        background-position-x: 96%;
                      }
                    }

                    .grouped {
                      padding: 5px 5px;
                    }

                    .disabling {
                      .__date-field2 {
                        opacity: 0.3;
                        background-color: #f4f4f4;
                        background-image: none;
                        background-repeat: no-repeat;
                        background-position: right;
                        width: 49%;
                        border-radius: 5px;
                        padding: 5px;
                      }
                    }

                    textarea {
                      height: 100px !important;
                    }
                  }
                }

                .__box {
                  max-width: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: column;

                  span {
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #646262;

                    b {
                      font-weight: 900;
                      color: #0884af;
                    }
                  }

                  .grouped,
                  input[type="text"],
                  textarea,
                  select {
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
                    background: #fff;
                    font-size: 13px;
                    resize: none;
                    display: flex;
                    margin-bottom: 0px;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px;
                    outline: none;
                    border-radius: 5px;
                    border: none;
                    color: #707070;
                    height: 45px;

                    .__date-field {
                      background: #f4f4f4;
                      width: 49%;
                      border-radius: 5px;
                      padding: 5px;
                    }
                  }

                  textarea {
                    height: 100px !important;
                  }
                }

                .input__dropdown {
                  margin-bottom: 20px;

                  .dropdown__wrapper {
                    .invite-widget {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .__textview {
                        width: 360px;
                      }

                      .btn-invite-via-email {
                        background: #1ba5a4;
                        color: #fff;
                        font-size: 14px;
                        font-weight: normal;
                        cursor: pointer;
                        border-radius: 5px;
                        height: 40px;
                        width: 160px;
                        padding: 3px 10px;
                      }
                    }
                  }
                }

                .__btns {
                  display: flex;
                  width: 30%;
                  margin-top: 30px;
                  justify-content: space-between;

                  .btn-cancel {
                    color: #1ba5a4;
                    cursor: pointer;
                    font-weight: normal;
                    height: 40px;
                    padding: 3px 20px;
                    font-size: 14px;
                    width: 100px;
                  }

                  .btn-save {
                    background: #1ba5a4;
                    color: #fff;
                    font-size: 14px;
                    font-weight: normal;
                    cursor: pointer;
                    border-radius: 5px;
                    height: 40px;
                    width: 100px;
                    padding: 3px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:disabled {
                      background: #8d8d8d;
                      opacity: 0.3;
                    }
                  }
                }
              }
            }

            .verify-form {
              background: #fff;
              border-radius: 5px;
              padding: 20px;

              @media (min-width: 300px) and (max-width: 630px) {
                padding: 0px;
              }

              .project-form {
                background: #f5f5f5;
                padding: 20px;

                @media (min-width: 300px) and (max-width: 630px) {
                  padding-top: 18px;
                  padding-left: 11px;
                  padding-right: 11px;
                  padding-bottom: 39px;
                }
              }

              .__label {
                margin-bottom: 8px;
                display: block;
              }

              .input-group {
                display: grid;

                input {
                  width: 100%;
                  height: 40px;
                  background: #fff;
                  padding: 0px 13px;
                  border-radius: 5px;
                  outline: none;
                }
              }

              .__btns {
                display: flex;
                width: 30%;
                margin-top: 30px;
                justify-content: space-between;

                .cancel {
                  color: #1ba5a4;
                  cursor: pointer;
                  font-weight: normal;
                  height: 30px;
                  padding: 3px 20px;
                  font-size: 14px;
                }

                .save {
                  background: #1ba5a4;
                  color: #fff;
                  font-size: 14px;
                  font-weight: normal;
                  cursor: pointer;
                  border-radius: 5px;
                  height: 30px;
                  padding: 3px 10px;

                  &:disabled {
                    background: #8d8d8d;
                    opacity: 0.3;
                  }
                }
              }
            }
          }
        }

        .multipane-resizer {
          width: 15px;
        }

        .item-view-more {
          height: 100%;
          overflow-y: auto;
          padding: 20px;
          padding-bottom: 40px;
          background: #fff;
          border-radius: 5px;
          width: 400px;
          min-width: 400px;
          max-width: 50%;

          @media (min-width: 300px) and (max-width: 630px) {
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 999999;
            display: flex;
            justify-content: center;
            padding-right: 0px;
            background: #f5f5f5;
          }

          .project-item {
            border-radius: 5px;
            margin-bottom: 10px;
            position: relative;

            @media (min-width: 300px) and (max-width: 630px) {
              padding-top: 80px;
            }

            .project-item-header {
              display: grid;
              grid-template-columns: 40px calc(100% - 40px);
              grid-column-gap: 10px;

              @media (min-width: 300px) and (max-width: 630px) {
                grid-template-columns: 100%;
                grid-column-gap: 10px;
                padding: 10px 12px;
              }

              @media (min-width: 300px) and (max-width: 630px) {
                position: fixed;
                top: 0px;
                left: 0px;
                right: 0px;
                background: #fff;
              }

              .project-item-brand {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @media (min-width: 300px) and (max-width: 630px) {
                  width: 40px;
                  height: 40px;
                  display: none;
                }

                img {
                  width: 35px;
                  height: 35px;
                  object-fit: contain;

                  @media (min-width: 300px) and (max-width: 630px) {
                    width: 33px;
                    height: 33px;
                  }
                }
              }

              .content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title-row {
                  padding-right: 9px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;

                  label {
                    display: block;
                    font-size: 13px;
                    margin-bottom: 2px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      font-size: 12px;
                    }
                  }

                  span {
                    color: #808080;
                    font-size: 11px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      font-size: 12px;
                    }
                  }

                  .date {
                    color: #808080;
                    font-size: 11px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 2px;

                    .separator {
                      margin: 0px 5px;
                      position: relative;
                      top: -3px;
                    }

                    @media (min-width: 300px) and (max-width: 630px) {
                      font-size: 11px;
                    }
                  }
                }

                .close-btn {
                  display: none;

                  @media (min-width: 300px) and (max-width: 630px) {
                    display: block;

                    span {
                      font-size: 24px;
                    }
                  }
                }

                .options {
                  display: flex;
                  align-items: flex-end;
                  flex-direction: column;
                  height: 50px;

                  @media (min-width: 300px) and (max-width: 630px) {
                    display: none;
                  }

                  .btns {
                    display: flex;
                    margin-top: -11px;
                  }

                  .verified-status {
                    color: #0590ab;
                    margin: 10px 0px;
                  }

                  .btn-verify {
                    height: 40px;
                    width: 100px;
                    outline: none;
                    color: #fff;
                    background-color: #1890a9;
                    border-radius: 5px;
                  }

                  .status {
                    color: #000;

                    font-size: 13px;
                  }

                  .toogle-panel-btn {
                    width: 19px;
                    padding: 0;
                    height: 22px;
                    font-size: 20px;
                    position: absolute;
                    bottom: 10px;
                    right: 3px;

                    img {
                      width: 15px;
                    }
                  }
                }
              }

              .toogle-panel-btn {
                padding: 1px 40px;
                font-size: 30px;
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                color: #ccc;
              }
            }

            .project-item-body {
              padding-bottom: 25px;

              .project-info {
                h5 {
                  text-align: left;
                  color: #707070;
                  font-size: 13px;
                  margin-bottom: 2px;
                }

                p {
                  text-align: left;
                  color: #000;
                  font-size: 12px;
                  margin-top: 0px;
                  font-weight: 300;
                }

                .collaboraters-widget {
                  padding: 10px 0px;
                  display: flex;
                  flex-wrap: wrap;

                  .item {
                    background: #1ba5a4 0% 0% no-repeat padding-box;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    padding: 4px 4px;
                    margin-right: 8px;
                    margin-bottom: 8px;

                    .profile-img {
                      width: 26px;
                      height: 26px;
                      border-radius: 50%;
                      margin-right: 5px;
                    }

                    .name {
                      color: #fff;
                    }
                  }
                }

                .skills-widget {
                  margin-top: 5px;

                  .skills_content__wrapper {
                    display: grid;
                    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
                    grid-gap: 10px;
                    margin-bottom: 10px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      grid-template-columns: 1fr;
                    }

                    .skill_box {
                      min-width: 0;
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      background: #f3f3f3;
                      border-radius: 5px;

                      .skill_box_header {
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 0px 15px;

                        label {
                          color: #0884af;
                          width: 100%;
                          font-size: 13px;
                        }
                      }

                      .skill_box_body {
                        padding: 0px 15px;

                        .empty-skills {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          min-height: 80px;
                          justify-content: center;

                          p {
                            text-align: center;
                            margin-bottom: 20px;
                            color: #8d8d8d;
                          }

                          button {
                            width: 133px;
                            height: 36px;
                            background: #1890a9 0% 0% no-repeat padding-box;
                            border-radius: 5px;
                            color: #fff;
                          }
                        }

                        .row__item {
                          display: flex;
                          margin-bottom: 10px;
                          justify-content: space-between;
                          width: 100%;

                          span {
                            color: #000000;
                            font-weight: normal;
                            margin-top: 2px;
                            font-size: 12px;
                            overflow: hidden;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }

                  .next_skills_content__wrapper {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 10px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      grid-template-columns: 1fr;
                    }

                    .skill_box {
                      min-width: 0;
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      background: #f3f3f3;
                      border-radius: 5px;
                      margin-bottom: 15px;

                      .skill_box_header {
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 0px 15px;

                        label {
                          color: #0884af;
                          width: 100%;
                          font-size: 13px;
                        }
                      }

                      .skill_box_body {
                        padding: 0px 15px;

                        .empty-skills {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          min-height: 80px;
                          justify-content: center;

                          p {
                            text-align: center;
                            margin-bottom: 20px;
                            color: #8d8d8d;
                          }

                          button {
                            width: 133px;
                            height: 36px;
                            background: #1890a9 0% 0% no-repeat padding-box;
                            border-radius: 5px;
                            color: #fff;
                          }
                        }

                        .row__item {
                          display: flex;
                          margin-bottom: 10px;
                          justify-content: space-between;
                          width: 100%;

                          span {
                            color: #000000;
                            font-weight: normal;
                            margin-top: 2px;
                            font-size: 12px;
                            overflow: hidden;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .project-item-body-option {
                display: flex;
                justify-content: flex-end;

                .toogle-panel-btn {
                  padding: 1px 9px;
                  font-size: 30px;
                  background: transparent;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  color: #ccc;
                  height: 36px;
                  width: 19px;
                  padding: 0;
                  height: 22px;
                  font-size: 20px;
                  position: relative;
                  top: -3px;
                  right: -5px;
                }
              }
            }
          }
        }
      }
    }

    .information-container {
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 40px;

      .information-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        .information-brand {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 100px;
            margin-right: 20px;
          }

          span {}
        }

        .information-options {
          .btn-edit-info {
            box-shadow: 0px 1px 3px #00000029;
            border: 1px solid #36adae;
            border-radius: 5px;
            color: #1885ad;
            padding: 10px 50px;
          }
        }
      }

      .information-body {
        padding: 20px;

        ul {
          margin-left: -38px;
          list-style: none;

          .information-item {
            margin-bottom: 30px;

            label {
              color: #808080;
            }

            p {
              color: #131313;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.card__body {
  padding: 20px;
  z-index: 9999;
  width: 100%;

  .__layout {
    .div {
      label {
        opacity: 0.51;
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #161615;
      }

      input {
        height: 30px;
        width: 100%;
        background: #fff;
        font-size: 14px;
        font-weight: 300;
        padding: 0 10px;
        margin: 10px 0;
        outline: none !important;
        border: none !important;
        border-radius: 5px;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;

        &:focus {
          border: none !important;
          outline: none !important;
        }

        &::placeholder {
          opacity: 0.5;
          font-style: italic;
        }
      }

      .msg {
        background: #fff;
        margin-top: 10px;
        border-radius: 5px;
        opacity: 0.68;
        padding: 10px;
        font-size: 13px;
        color: #676767;
      }
    }
  }

  .__cont {
    display: flex;
    flex-direction: column;

    .icons {
      display: flex;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 0.7px solid #a0a0a0;
      margin-top: 10px;

      div {
        margin-right: 10px;

        label {
          font-size: 12px;
          margin-right: 10px;
        }

        input {
          margin-top: 5px;
        }
      }
    }

    .body {
      padding: 20px;
      border-radius: 5px;
      background: #f4f4f4;
      border: 1px solid #707070;

      .template {
        width: 100%;
        background: #fff;
        box-shadow: 0 6px 5px rgba(0, 0, 0, 0.16);
        margin-right: 10px;

        button {
          height: 10px;
          font-size: 4px;
          outline: none;
          color: #fff;
          border: none;
          background: #0e9695;
        }

        .header {
          display: flex;
          width: 100%;

          .first {
            display: flex;
            padding-left: 10px;
            padding-right: 10px;
            flex-direction: column;

            img {
              width: 30px;
              height: 30px;
              border-radius: 100%;
            }

            h2 {
              font-size: 6px;
              margin-top: 10px;
            }
          }

          .second {
            background: #0075a0;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;

            .titles {
              display: flex;
              color: #fff;
              width: 100%;
              justify-content: space-between;
              padding-left: 10px;
              padding-right: 10px;

              h2 {
                font-size: 4px;
              }

              button {
                height: 10px;
                font-size: 4px;
                outline: none;
                border: none;
                background: #0e9695;
              }
            }
          }
        }

        .contents {
          display: flex;
          width: 100%;

          span,
          p,
          button {
            font-size: 4px !important;
          }

          .left {
            width: 100px;
          }

          .right {
            flex-grow: 1;
          }
        }
      }
    }

    .actiond {
      margin-top: 10px;
      display: flex;

      button {
        background: #1890a9;
        color: #fff;
        border-radius: 5px;
        padding: 5px 10px;
        justify-content: space-between;
        align-items: center;
      }

      .cancel {
        background: transparent;
        color: #0590ab;
      }
    }
  }

  .__input {
    margin: 20px 0;
    width: 100%;

    span {
      color: #979797;
      font-size: 14px;
      font-weight: normal;
    }

    input {
      width: 100%;
      color: gray;
      font-size: 14px;
      height: 30px;
      outline: none;
      background: #e9ebed;
      border-radius: 3px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
      padding: 2px 10px;
    }
  }

  .btns {
    display: flex;
    width: 50%;
    float: right;
    justify-content: space-between;

    .cancel {
      color: #1ba5a4;
      cursor: pointer;
      font-weight: normal;
      height: 22px;
      padding: 1px 20px;
      font-size: 14px;
    }

    .save {
      background: #1ba5a4;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      border-radius: 5px;
      height: 22px;
      padding: 1px 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        background: #8d8d8d;
        opacity: 0.3;
      }
    }
  }

  .btns-2 {
    display: flex;
    float: right;
    margin-top: 20px;

    .save {
      width: 100px;
      height: 40px;
      font-size: 14px;
      outline: none;
      opacity: 0.97;
      border-radius: 0.6vh;
      font-family: "Nunito", sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(to right,
          #1d9694 0%,
          #199295 36%,
          #108798 68%,
          #01759e 98%,
          #00749f 100%);
    }

    .cancel {
      width: 100px;
      height: 40px;
      font-size: 14px;
      outline: none;
      opacity: 0.51;
      font-family: "Nunito", sans-serif;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #161615;
    }
  }

  .__body {
    background: #f0eaea;
    padding: 20px;

    .__rowed {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      justify-content: space-between;

      span {
        color: #646262;
        font-size: 12px;
      }

      input,
      select {
        background: #ffffff;
        border-radius: 5px;
        padding: 5px 10px;
        outline: none;
        width: 80%;
        font-size: 12px;
        color: #c4c4c4;
      }

      select {
        width: 30%;
      }

      img {
        cursor: pointer;
      }

      .img {
        max-width: 15px;
        max-height: 15px;
      }
    }
  }

  .__rowed {
    position: relative;
  }

  .dropdown__body {
    background: #fff !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    position: absolute;
    width: 80%;
    top: 30px;
    z-index: 10;

    .dropdown__item {
      display: flex;
      cursor: pointer;
      padding: 0px 15px 5px 15px;
      margin-top: 10px;
      border-bottom: 1px solid #e6e6e6;
      flex-direction: row;

      img {
        width: 19px;
        border-radius: 100px;
        height: 19px;
        margin-right: 8px;
      }

      .text {
        display: flex;
        color: #808080;
        margin-top: -3px;
        font-size: 8pt;
        flex-direction: column;

        .small {
          font-size: 6pt;
          font-weight: normal;
          margin-top: -2px;
        }
      }
    }
  }
}
</style>