<template>
  <div class="page-wrapper">
    <div class="page-header">
      <WidgetToolbar :title="accounts_data ? `Accounts(${accounts_data.total})` : `Accounts`"
        :icon="require('@/assets/icon/accounts-active.svg')">
        <div class="options">
          <widgetSearch class="search-account" placeholder="Search here..." icon="right" v-model="filter_form.query"
            @keyup="searchAccount">
          </widgetSearch>

          <div class="v-line-divider" style="height: 40px"></div>

          <widgetDropdown v-model="main_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              <span v-if="filter_data == 'mine'">My Accounts</span>
              <span v-else-if="filter_data == 'all'">All Accounts</span>
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in data_filter" :key="index"
                @click="filterByAccountType(item)">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>

          <div class="v-line-divider" style="height: 40px"></div>


          <widgetDropdown v-model="sort_input" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Sort
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in sort_filter" :key="index"
                @click="sort_filter_data = item.value, sort_input = false">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="tags_input" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Tags
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <widgetListSelecter v-model="tags_filter" @close="tags_input = false" @confirm="(data) => {
                tags_input = false;
                filterByTag(data);
              }
                "></widgetListSelecter>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="status_filter_input" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              <span v-if="selected_status_filter == 'active'">Active</span>
              <span v-else-if="selected_status_filter == 'passive'">Passive</span>
              <span v-else>Status</span>
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in status_filter" :key="index"
                @click="filterByStatus(item)">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="category_filter_input" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              <span v-if="selected_category_filter == 'gold'">Gold</span>
              <span v-else-if="selected_category_filter == 'silver'">Silver</span>
              <span v-else-if="selected_category_filter == 'bronze'">Bronze</span>
              <span v-else>Category</span>
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in category_filter" :key="index"
                @click="filterByCategory(item.value)">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="h-spacer"></div>
          <widgetButton :loading="false" @click="addNew()" class="btn-secondary">
            Add Account +
          </widgetButton>
        </div>
      </WidgetToolbar>
    </div>
    <div class="page-tab">
      <div></div>

      <div class="page-tab-options row">
        <!-- <widgetButton
          v-if="show_selected_options"
          :loading="false"
          @click="addInternship()"
          class="btn-primary"
        >
          Generate LIA
        </widgetButton>
        <div class="h-spacer"></div> -->
        <app-dialog v-if="show_selected_options" title="Delete Account"
          ask="Are you sure you want to permanently delete selected accounts?" yesText="Delete" noText="Cancel"
          @onComfirm="deleteSelectedAccount()">
          <widgetButton :loading="false" class="btn-transparent fit-content">
            <img src="@/assets/icon/trash-red.svg" />
          </widgetButton>
        </app-dialog>
        <div v-if="isActiveFilter" class="h-spacer"></div>
        <widgetButton v-if="isActiveFilter" class="btn-transparent-secondary" href="#" @click="clearFilter()">
          Clear Filter</widgetButton>
        <div v-if="show_selected_options" class="h-spacer"></div>
        <widgetButton v-if="show_selected_options" class="btn-secondary" href="#" @click="sendEmailSelectedAccount()">
          Send
          Email</widgetButton>

        <div v-if="show_selected_options" class="h-spacer"></div>
        <widgetDropdown v-if="show_selected_options" v-model="selected_list_dropdown" :right="true" :hover="false"
          :interactive="true" class="selected-dropdown">
          <button class="btn-dropdown">
            <img class="icon" style="height: 20px" src="@/assets/icon/menu-dot.svg" />
          </button>
          <div slot="dropdown" class="dropdown-widget right">
            <a class="dropdown-item" href="#" @click="exportSelectedAccounts()">Export Accounts</a>
            <a class="dropdown-item" href="#" @click="updateSelectedAccount()">Edit</a>
            <a class="dropdown-item" href="#" @click="sendEmailSelectedAccount()">Send Email</a>
          </div>
        </widgetDropdown>
      </div>
    </div>
    <div class="page-body">
      <div class="account-items" @scroll="onPageScoll">
        <div class="table-widget">
          <table class="table-data" v-if="accounts_data">
            <thead class="table-header">
              <tr>
                <th width="400">
                  <div class="space-between">
                    <div class="row">
                      <input @change="onSelectAll" class="checkbox" type="checkbox" />
                      <div class="h-spacer"></div>
                      <div>Name</div>
                    </div>
                    <widgetAscDesc v-model="filter_form.sort_by_name" @click="sortByName"></widgetAscDesc>
                  </div>
                </th>

                <th width="300">
                  <div class="space-between">
                    <div class="row">
                      <div>Account owner</div>
                    </div>
                  </div>
                </th>


                <!-- <th class="phone-hide" width="230">Account Contact</th> -->
                <!-- <th class="phone-hide" width="200">LIA Contact</th> -->
                <th class="phone-hide" width="300">Tags</th>
                <th class="phone-hide" width="200">
                  <div class="space-between">
                    <div> Status</div>
                    <widgetAscDesc v-model="filter_form.sort_by_status" @click="sortByStatus"></widgetAscDesc>
                  </div>
                </th>
                <th class="phone-hide" width="80">
                  <div class="space-between">
                    <div>Category</div>
                    <widgetAscDesc v-model="filter_form.sort_by_category" @click="sortByCategory"></widgetAscDesc>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <template v-for="(item, index) in accounts_data.data">
                <tr :key="index" v-if="item.name">
                  <td>
                    <div class="row">
                      <input v-model="item.checked" @change="checkItem(item)" class="checkbox" type="checkbox" />
                      <div class="h-spacer"></div>
                      <widgetProfile :title="item.name" subtitle="" file_name="" path=""></widgetProfile>
                    </div>
                  </td>

                  <td>
                    <div class="account-owner" v-if="item.owner">
                      {{ item.owner.first_name | validate }} {{ item.owner.last_name | validate }}
                    </div>
                  </td>

                  <!--                 
                <td
                  @click="
                    $router.push({
                      name: 'account-view',
                      params: { type: 'overview', id: item.id },
                    })
                  "
                  class="phone-hide"
                >
                  <div class="contact-widget">
                    <p class="email">{{ item.email | validate }}</p>
                    <p class="phone">{{ item.phone | validate }}</p>
                  </div>
                </td>

                <td
                  @click="
                    $router.push({
                      name: 'account-view',
                      params: { type: 'overview', id: item.id },
                    })
                  "
                  class="phone-hide"
                >
                  <div class="contact-widget" v-if="item.contact">
                    <p class="email" v-if="item.contact.email">
                      {{ item.contact.email | validate }}
                    </p>
                    <p class="phone" v-if="item.contact.phone_alt">
                      {{ item.contact.phone_alt | validate }}
                    </p>
                  </div>
                </td> -->

                  <td>
                    <widgetTagsViewer :tags="item.tags" @remove="(e) => {
                      removeAccountTags(item.id, e.id, e.i, item.tags);
                    }
                      "></widgetTagsViewer>
                  </td>

                  <td>
                    <div v-if="item.status == 'active' || item.status == 'Active'" class="account-status active">
                      Active
                    </div>
                    <div v-if="item.status == 'passive' || item.status == 'Passive'
                    " class="account-status passive">
                      Passive
                    </div>
                  </td>

                  <td>
                    <div class="category">
                      <span v-if="item.category == 'gold' || item.category == 'Gold'
                      ">Gold</span>
                      <span v-if="item.category == 'silver' || item.category == 'Silver'
                      ">Silver</span>
                      <span v-if="item.category == 'bronze' || item.category == 'Bronze'
                      ">Bronze</span>
                    </div>
                    <div class="table-row-option">
                      <app-dialog title="Delete Account" ask="Are you sure you want to permanently delete this account?"
                        yesText="Delete" noText="Cancel" @onComfirm="deleteAccount(item.id)">
                        <widgetButton :loading="false" class="btn-transparent fit-content">
                          <img width="18" src="@/assets/icon/trash-red.svg" />
                        </widgetButton>
                      </app-dialog>
                      <div class="h-spacer"></div>
                      <widgetButton :loading="false" @click="updateAccount(item)" class="btn-transparent fit-content">
                        <img src="@/assets/icon/edit-gray.svg" />
                      </widgetButton>
                    </div>
                  </td>

                </tr>
              </template>
            </tbody>
          </table>

          <div class="content-loading-container" v-if="account_loading">
            <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="account_loading"
              :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
          </div>

          <div class="not-found" v-if="accounts_data &&
            accounts_data.data.length == 0 &&
            !account_loading
          ">
            <widgetNotFoundWidget title="No Account Found" content="Looks like you haven’t added any account yet"
              btntext="Add new account" @click="addNew()">
              <img class="widget-img" src="@/assets/admin-icon/add-student-icon.svg" />
            </widgetNotFoundWidget>
          </div>
        </div>
      </div>
    </div>

    <!-- Add - Update Modal -->
    <addUpdateAccountModal v-if="show_form_add_update" :content="selected_account" @success="submitAccountData()"
      @close="show_form_add_update = false" @onGenerateInternship="onAddInternship"></addUpdateAccountModal>

    <!--Update many Account -->
    <updateSelectedAccountModal v-if="show_selected_accounts_modal" :content="selected_accounts"
      @success="submitAccountData()" @close="show_selected_accounts_modal = false"
      @onGenerateInternship="onAddInternship">
    </updateSelectedAccountModal>

    <!-- Add LIA -->
    <addInternshipModal v-if="show_add_internship_modal" :content="null" type="gen"
      :account_data="accounts_for_internship" @success="show_add_internship_modal = false"
      @close="show_add_internship_modal = false"></addInternshipModal>

    <!-- Send Mail -->
    <sendMailModal v-if="show_send_mail_modal" :content="selected_accounts" type="account" @success="sendEmailSuccess()"
      @close="show_send_mail_modal = false"></sendMailModal>
  </div>
</template>
<script>
import addUpdateAccountModal from "@/views/pages/UsersManagement/tabs/Accounts/components/add_update_account_modal.vue";
import updateSelectedAccountModal from "@/views/pages/UsersManagement/tabs/Accounts/components/update_selected_account_modal.vue";
import addInternshipModal from "@/views/pages/UsersManagement/tabs/internship/components/add_internship_modal.vue";
import sendMailModal from "@/views/pages/UsersManagement/tabs/Accounts/components/send_mail_modal.vue";

import { eventBus } from "@/main";
export default {
  components: {
    addUpdateAccountModal,
    updateSelectedAccountModal,
    addInternshipModal,
    sendMailModal,
  },
  data: () => ({
    tab: 1,
    show_send_mail_modal: false,
    add_many_active_tab: 1,
    show_form_add_update: false,
    show_selected_accounts_modal: false,
    show_add_internship_modal: false,
    tag_filter_data: "",
    tags_filter: [],
    sort_filter_data: "",
    sort_filter: [
      {
        value: "create_date",
        name: "Create Date",
      },
      {
        value: "name",
        name: "Name",
      },
    ],
    data_filter: [
      {
        value: "mine",
        name: "My Accounts",
      },
      {
        value: "all",
        name: "All Accounts",
      },
    ],
    filter_data: "all",
    category_filter: [
      {
        value: "",
        name: "All",
      },
      {
        value: "gold",
        name: "Gold",
      },
      {
        value: "silver",
        name: "Silver",
      },
      {
        value: "bronze",
        name: "Bronze",
      },
    ],
    category_filter_input: false,
    selected_category_filter: "",
    adding_acc_loading: false,
    accounts_data: null,
    selected_account: null,
    selected_accounts: [],
    show_selected_options: false,
    selected_account_status: "active",
    show_status_account_update_selected: false,
    show_tags_account_update_selected: false,
    sort_input: false,
    tags_input: false,
    main_filter: false,
    selected_list_dropdown: false,
    accounts_for_internship: {},
    filter_form: {
      type: "all",
      status: "",
      category: "",
      url_type: "",
      query: "",
      tags: [],
      sort: "",
      sort_by_name: "",
      sort_by_status: "",
      sort_by_category: "",
      location: "",
      next_page_url: "",
    },
    account_loading: false,
    isAllAccountSelected: false,
    status_filter_input: false,
    selected_status_filter: "",
    status_filter: [
      {
        value: "",
        name: "All",
      },
      {
        value: "active",
        name: "Active",
      },
      {
        value: "passive",
        name: "Passive",
      },
    ],
  }),
  mounted() {
    let vm = this;
    vm.getTags();
    vm.getAccountsData("init");
  },
  computed: {
    isActiveFilter() {
      let vm = this;
      return (
        vm.filter_form.query ||
        vm.filter_form.tags.length > 0 ||
        vm.filter_form.sort ||
        vm.filter_form.category ||
        vm.filter_form.status ||
        vm.filter_form.sort_by_name ||
        vm.filter_form.sort_by_status ||
        vm.filter_form.sort_by_category ||
        vm.filter_form.location
      );
    },
  },
  methods: {
    clearFilter() {
      let vm = this;
      vm.filter_form = {
        type: "all",
        status: "",
        category: "",
        url_type: "",
        query: "",
        tags: [],
        sort: "",
        sort_by_name: "asc",
        sort_by_status: "asc",
        sort_by_category: "asc",
        location: "",
        next_page_url: ""
      }
      vm.filter_data = "all";
      vm.selected_status_filter = "";
      vm.selected_category_filter = "";
      vm.getAccountsData("init");
    },
    onPageScoll({ target }) {
      let vm = this;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        if (
          !vm.account_loading &&
          vm.accounts_data.current_page < vm.accounts_data.last_page
        ) {
          vm.getAccountsData("next");
        }
      }
    },
    exportSelectedAccounts() {
      let vm = this;
      let ids = vm.validateChecked("data");
      vm.$store
        .dispatch("ACCOUNT_EXPORT", {
          ids: ids.map((item) => item.id),
          all: vm.isAllAccountSelected,
          filters: vm.filter_form,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "accounts.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    sendEmailSuccess() {
      let vm = this;
      vm.show_send_mail_modal = false;
    },
    sendEmailSelectedAccount() {
      let vm = this;
      vm.selected_accounts = vm.validateChecked("data");
      vm.show_send_mail_modal = true;
    },
    removeAccountTags(account_id, id, index, tags) {
      let vm = this;
      vm.$store
        .dispatch("ACCOUNT_DELETE_TAG", {
          id: id,
          account_id: account_id,
        })
        .then((response) => {
          tags.splice(index, 1);
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Tag removed",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    updateSelectedAccountData(type) {
      let vm = this;
      let ids = vm.validateChecked("ids");
      let tags = vm.tags.added.map((item) => {
        return item;
      });
      vm.adding_acc_loading = true;
      vm.$store
        .dispatch("ACCOUNT_UPDATE_SELECTED", {
          ids: ids,
          type: type,
          tags: tags,
          status: vm.selected_account_status,
        })
        .then((response) => {
          vm.show_account_update_selected = false;
          vm.adding_acc_loading = false;
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Accounts updated",
            type: "success",
            duration: 3000,
          });
          vm.getAccountsData("init");
        })
        .catch((error) => {
          vm.adding_acc_loading = false;
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    showSelectedAccountUpdateModel(type) {
      let vm = this;
      if (type == "status") {
        vm.show_status_account_update_selected = true;
      }
      if (type == "tags") {
        vm.tags = {
          added: [],
          removed: [],
        };
        vm.show_tags_account_update_selected = true;
      }
    },
    deleteSelectedAccount() {
      let vm = this;
      let ids = vm.validateChecked("ids");
      vm.$store
        .dispatch("ACCOUNT_DELETE_SELECTED", { ids: ids })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Selected accounts are now deleted",
            type: "success",
            duration: 3000,
          });
          vm.getAccountsData("init");
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    checkItem(item) {
      let vm = this;
      vm.validateChecked();
    },
    selectTab(type) {
      let vm = this;
      vm.$emit("tab", type);
    },
    onSelectAll({ target }) {
      let vm = this;
      vm.isAllAccountSelected = target.checked;
      if (target.checked) {
        vm.accounts_data.data = vm.accounts_data.data.map((item) => {
          eventBus.$set(item, "checked", true);
          return item;
        });
      } else {
        vm.accounts_data.data = vm.accounts_data.data.map((item) => {
          eventBus.$set(item, "checked", false);
          return item;
        });
      }
      vm.validateChecked();
    },
    validateChecked(type = "validate") {
      let vm = this;
      if (type == "validate") {
        vm.show_selected_options =
          vm.accounts_data.data
            .map((item) => item.checked)
            .filter((item) => item == true).length > 0;
        return;
      }
      if (type == "ids") {
        return vm.accounts_data.data
          .filter((item) => item.checked == true)
          .map((item) => item.id);
      }
      return vm.accounts_data.data
        .filter((item) => item.checked == true)
        .map((item) => item);
    },
    updateSelectedAccount() {
      let vm = this;
      vm.selected_accounts = vm.validateChecked("data");
      vm.show_selected_accounts_modal = true;
    },
    deleteAccount(id) {
      let vm = this;
      vm.$store
        .dispatch("ACCOUNT_DELETE", { account_id: id })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Account is now deleted",
            type: "success",
            duration: 3000,
          });
          vm.getAccountsData("init");
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    // Filter functions
    searchAccount({ target }) {
      let vm = this;
      vm.filter_form.query = target.value;
      vm.getAccountsData("init");
    },
    filterByTag(tags) {
      let vm = this;
      vm.filter_form.tags = tags;
      vm.getAccountsData("init");
    },
    filterBySort(order) {
      let vm = this;
      vm.filter_form.sort = order;
      vm.filter_form.sort_by_name = '';
      vm.filter_form.sort_by_status = '';
      vm.filter_form.sort_by_category = '';
      vm.getAccountsData("init");
    },
    sortByName(order) {
      let vm = this;
      vm.filter_form.sort_by_name = order;
      vm.filter_form.sort_by_status = '';
      vm.filter_form.sort_by_category = '';
      vm.filter_form.sort = '';
      vm.getAccountsData("init");
    },
    sortByStatus(order) {
      let vm = this;
      vm.filter_form.sort_by_status = order;
      vm.filter_form.sort_by_category = '';
      vm.filter_form.sort_by_name = '';
      vm.filter_form.sort = '';
      vm.getAccountsData("init");
    },
    sortByCategory(order) {
      let vm = this;
      vm.filter_form.sort_by_category = order;
      vm.filter_form.sort_by_name = '';
      vm.filter_form.sort = '';
      vm.filter_form.sort_by_status = '';
      vm.getAccountsData("init");
    },
    filterByCategory(name) {
      let vm = this;
      vm.category_filter_input = false;
      vm.selected_category_filter = name;
      vm.filter_form.category = name;
      vm.getAccountsData("init");
    },
    filterByStatus(item) {
      let vm = this;
      vm.status_filter_input = false;
      vm.selected_status_filter = item.value;
      vm.filter_form.status = item.value;
      vm.getAccountsData("init");
    },
    onFilterByLocation(data) {
      let vm = this;
      if (data.name) {
        vm.filter_form.location = data.name;
        vm.getAccountsData("init");
      }
    },
    filterByAccountType(item) {
      let vm = this;
      vm.filter_data = item.value;
      vm.filter_form.type = item.value;
      vm.main_filter = false;
      vm.getAccountsData("init");
    },
    // ===
    getAccountsData(type = "init") {
      let vm = this;
      vm.show_selected_options = false;
      if (type == "init") {
        vm.accounts_data = null;
      }
      vm.filter_form.url_type = type;
      vm.filter_form.next_page_url = vm.accounts_data
        ? vm.accounts_data.next_page_url
        : "";
      vm.account_loading = true;

      vm.$store
        .dispatch("ACCOUNT_GET_DATA", vm.filter_form)
        .then((responce) => {
          vm.account_loading = false;
          responce.data.data = responce.data.data.map((item, index) => {
            if (vm.isAllAccountSelected) {
              eventBus.$set(item, "checked", true);
            } else {
              eventBus.$set(item, "checked", false);
            }
            eventBus.$set(item, "expand_student", false);
            if (index == 0 && type == "init") {
              eventBus.$set(item, "expanded", true);
            } else {
              eventBus.$set(item, "expanded", false);
            }
            return item;
          });

          if (type == "init") {
            vm.accounts_data = responce.data;
            if (vm.accounts_data.current_page < vm.accounts_data.last_page) {
              vm.getAccountsData("next");
            }
          } else {
            vm.accounts_data.next_page_url = responce.data.next_page_url;
            vm.accounts_data.current_page = responce.data.current_page;
            vm.accounts_data.last_page = responce.data.last_page;
            responce.data.data.forEach((item) => {
              vm.accounts_data.data.push(item);
            });
          }
        })
        .catch((error) => {
          vm.account_loading = false;

          console.error({ error });
        });
    },
    getTags() {
      let vm = this;
      vm.$store
        .dispatch("ACCOUNT_GET_TAGS")
        .then((response) => {
          vm.tags_filter = response.data.map((item) => {
            return {
              value: item.name,
              name: item.name,
            };
          });
        })
        .catch((error) => { });
    },
    addInternship() {
      let vm = this;
      let payload = {
        type: "many",
        origin: "account",
        accounts: vm.validateChecked("data"),
      };
      vm.accounts_for_internship = payload;
      vm.show_add_internship_modal = true;
    },
    onAddInternship(payload) {
      let vm = this;
      vm.show_form_add_update = false;
      vm.show_selected_accounts_modal = false;
      vm.selected_accounts = null;
      vm.accounts_for_internship = payload;
      vm.show_add_internship_modal = true;
    },
    updateAccount(item) {
      let vm = this;
      vm.selected_account = item;
      vm.show_form_add_update = true;
    },
    addNew() {
      let vm = this;
      vm.selected_account = null;
      vm.show_form_add_update = true;
    },
    submitAccountData() {
      let vm = this;
      vm.show_form_add_update = false;
      vm.show_selected_accounts_modal = false;
      vm.selected_accounts = null;
      vm.getAccountsData("init");
    },
  },
  watch: {
    sort_filter_data(val) {
      let vm = this;
      vm.filterBySort(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ebebeb;
  padding: 5px;

  .page-header {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;

    .options {
      display: flex;
      align-items: center;

      .search-account {
        width: 250px;
      }

      .btn-add {
        margin-left: 20px;
      }
    }
  }

  .page-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin-bottom: 5px;

    .selected-dropdown {
      position: relative;
    }

    .page-tab-options {
      margin-right: 20px;
    }
  }

  .page-body {
    height: calc(100% - 80px);
    background: #fff;
    border-radius: 5px;
    padding-bottom: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;

    .account-items {
      overflow-y: auto;
      height: 100%;

      .table-widget {
        .table-data {
          .table-body {
            tr {
              .table-row-option {
                display: none;
                align-items: center;
              }

              &:hover {
                .table-row-option {
                  display: flex;
                  height: 27px;
                }

                .category {
                  display: none;
                  height: 36px;
                }
              }
            }
          }

          .contact-widget {
            p {
              margin: 1px 0px;
            }
          }

          .account-location {
            width: 180px;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .tags-widget {}

          .account-status {
            width: 76px;
            height: 25px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
              background: #1ba5a53a;
              color: #007d7c;
            }

            &.passive {
              background: #0885af2c;
              color: #00759e;
            }

            &.onhold {
              background: #ffc2733d;
              color: #dd7d00;
            }
          }

          .category {
            font-weight: 800;
          }
        }
      }
    }
  }
}

.form-widget-modal {
  width: 500px;

  .modal-body {
    .form-widget {
      .form-widget-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        input[type="radio"] {
          position: relative;
          top: -3px;
          left: -5px;
          margin-right: 8px;
        }

        img {
          margin-left: 5px;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .line {
      border-bottom: 1px solid #ddd;
      margin: 10px 0px;
    }
  }

  .wizard-widget {
    .wizard-widget-header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 20px;

      .wizard-indicator {
        display: flex;
        align-items: center;

        .indicator-item {
          height: 5px;
          width: 50px;
          background: #d5d5d5;
          margin: 0px 5px;

          &.active {
            background: #1ba5a4;
          }
        }
      }
    }

    .wizard-widget-body {
      .tab {
        .template-widget {
          h1 {
            font-size: 18px;
          }

          .btn-download {
            width: 200px;
            height: 45px;
            background: #1890a9 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            img {
              margin-left: 10px;
            }
          }
        }

        .upload-widget {
          h1 {
            font-size: 18px;
          }

          .btn-upload {
            width: 200px;
            height: 45px;
            background: #1890a9 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            img {
              margin-left: 10px;
            }
          }

          .selected-file {
            margin-top: 20px;
            display: flex;
            align-items: center;
          }
        }

        .wizard-widget-footer-options {
          display: flex;
          justify-content: center;
          padding-top: 40px;

          .btn-next {
            width: 100px;
            height: 45px;
            background: #229ea5;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
